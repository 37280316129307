@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

.b-headings-overview {
  padding: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: $primary-color-lighter;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__heading {
    font-size: 1rem;
    width: 100%;
  }

  &__link {
    animation: fadeIn 0.2s;
    box-shadow: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    column-gap: 1rem;
    align-items: center;
  }

  &__list {
    padding-left: 0;
    animation: fadeIn 0.2s;

    li:not(last-child) {
      margin-bottom: 0.5rem;
      list-style: none;
    }
  }

  &__button {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
