.b-section-sidebar {
  position: sticky;
  top: $space-md;
  overflow-y: auto;
  padding: 0 0.25rem;

  @media(width <= 40rem) {
    margin-top: $space-md;
    margin-bottom: $space-md;
  }

  &--non-stick {
    position: inherit;
    overflow: inherit;
  }

  &--bottom {
    >nav {
      max-height: 50vh !important;
    }
  }

  &--subpages {
    .b-section-sidebar__sub-link {
      &:last-child:hover {
        margin-left: $space-md;
      }

      &--active {
        &:last-child:hover {
          margin-left: 0;
        }
      }
    }

    .b-section-sidebar__link {
      &:last-child:hover {
        border-bottom: none;
      }
    }
  }

  >nav {
    max-height: 85vh;

    &> :first-child:focus-visible {
      margin-top: 0.25rem;
    }

    &> :last-child:focus-visible {
      margin-top: 0.25rem;
    }

    &::-webkit-scrollbar {
      appearance: none;
      width: 10px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(0 0 0 / 50%);
      box-shadow: 0 0 1px rgb(255 255 255 / 50%);
      border-radius: 4px;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    padding-top: $space-sm;
    padding-bottom: $space-sm;
    padding-left: $space-xs;

    @include underline($grey-light);

    font-size: $font-xs;

    a {
      @include underline(transparent);
    }

    &--thick {
      padding-top: 0;
      padding-bottom: $space-xs;
      border-top: 0;
      font-weight: 700;
      font-size: $font-sm;
    }
  }

  &__icon {
    height: 3em;
    width: auto;
    flex-shrink: 0;
    margin-right: 1em;
  }

  &__link {
    display: block;
    padding: $space-xs 0 $space-xxs 0;
    font-size: $font-xs;

    @include underline($grey-light);

    border-top: 1px solid $grey-light;

    &:hover:not(.b-section-sidebar__link--children) {
      color: $black !important;
      background-color: $primary-color-lightest;
      border-top: 1px solid $primary-color-light;

      &+.b-section-sidebar__link {
        border-top: 1px solid $primary-color-light;
      }
    }

    &:last-child:hover {
      border-bottom: 1px solid $primary-color-light;
    }

    &--active {
      color: $black !important;
      background-color: $primary-color-lightest;
      border-top: 1px solid $primary-color-light;
      display: block;

      &+.b-section-sidebar__link {
        border-top: 1px solid $primary-color-light;
      }

      .b-section-sidebar__meta {
        font-weight: 600;
      }
    }
  }

  &__sub-link {
    display: block;
    padding: $space-xs $space-sm $space-xs;
    border: 0;
    transition: margin .1s ease-out;
    box-shadow: none;

    &:hover {
      color: $primary-color;
    }

    &--active {
      background: $primary-color-lighter;
      background-image: url($arrow-right);
      background-size: .8em;
      background-repeat: no-repeat;
      background-position: left center;
      background-position-x: 1.5rem;
      font-weight: 600;
      padding-left: $space-lg*1.25;
    }

    &--subheading {
      padding-left: $space-lg*1.25;
      background-position-x: 1rem;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__meta {
    display: flex;
    padding-bottom: $space-xxs;
    padding-left: $space-md*1.25;
    padding-right: $space-sm;
    box-shadow: none;
    background-image: url($arrow-down);
    background-size: .8em;
    background-repeat: no-repeat;
    background-position: left center;
    background-position-x: 0.5rem;
    background-position-y: 0.35rem;

    &:hover {
      color: $primary-color;
    }
  }

  &__meta-prefix {
    padding-right: $space-xs;
    color: $primary-color;
  }

  &__prefix {
    margin-right: 1em;
    flex-shrink: 0;
  }

  &__dialog {
    background-color: rgb(0 0 0 / 80%);
    height: 100%;
    width: 100%;
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    z-index: 999;

    &-open-button {
      font-size: $font-xs;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
    }
  }

  &__dialog-close-button {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-left: auto;

    svg {
      color: $blue;
    }
  }

  &__dialog-content {
    background-color: #fff;
    padding: 2rem 1rem;
    margin-top: 10vh;
    margin-left: 1rem;
    margin-right: 1rem;
    max-height: 80vh;
    height: fit-content;
    width: 100%;
    overflow-y: auto;
  }
}

.b-section-sidebar-reports {
  // reset
  & a {
    box-shadow: none;

    &:visited,
    &:link {
      color: inherit;
    }

    &:hover {
      color: $blue;
    }
  }

  &__root-list {
    font-size: $font-xs;
    list-style: none;
    padding-inline: 0;
    padding-inline: 0 0;
    margin-inline: 0;
    margin-inline: 0 0;
  }

  &__prefix {
    display: inline-block;
    min-width: 2rem;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__parent {
    width: 100%;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    margin-bottom: -1px; // This is so that the borders will overlap. dirty fix, I know.

    &:hover {
      background-color: $blue-lighter;
    }

    &--active {
      &:hover {
        background-color: inherit;
        font-weight: inherit;
      }
    }

    &-anchor {
      padding-left: 0.5rem;
      display: flex;
      align-items: center;

      &--active {
        font-weight: 600;
      }
    }

    &-list {
      list-style: none;
      padding-inline: 0;
      padding-inline: 0 0;
      margin-inline: 0;
      margin-inline: 0 0;
      font-weight: 400;
    }

    &-toggle-button {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        max-height: 1rem;
        max-width: 1rem;
      }
    }

    &-link {
      padding: 0.5rem 0;
      min-height: 2rem;
      display: flex;
      justify-content: space-between;

      &>button {
        font-size: 1.75rem;
        margin: 0;
        color: $blue;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      &--open {
        font-weight: 600;
      }

      &--active {
        background-color: $blue-lighter;
      }
    }
  }



  &__child {
    &--active {
      font-weight: 600;
      background-color: $blue-lighter;
    }

    &-anchor {
      display: flex;
      padding: 0.5rem;

      &:hover {
        background-color: $blue-lightest;
      }
    }

    &-list-item {
      list-style: none;
      padding-inline: 0;
      padding-inline: 0 0;
    }
  }

  &-sticky {
    position: sticky;
    top: $space-md;
    overflow-y: auto;

    @media(width <= 40rem) {
      margin-top: $space-md;
      margin-bottom: $space-md;
    }
  }
}
