:root {
  // TODO: add icons or change paths. Doing this one at a time and will delete the rest when finished
  // --icon-arrow-right: url("../images/icons/arrow-right.svg");
  --icon-chevron: url("../static/icons/icon-chevron.svg");
  --icon-plus: url("../static/icons/plus.svg");
  --icon-minus: url("../static/icons/minus.svg");
  --icon-download: url("../static/icons/icon-download.svg");
  --icon-ellipse: url("../static/icons/ellipse.svg");
  // --icon-external-link: url("../images/icons/external-link.svg");
  --icon-arrow-long: url("../static/icons/arrow-right-long.svg"); // path from hdir-bundle
  // --icon-info: url("../images/icons/info.svg");
}

.icon,
.icon-start::before,
.icon-end::after {
  content: "";
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: var(--icon-position, center);
  mask-image: var(--icon-url);
  display: inline-block;
  width: var(--icon-width, 1em);
  height: var(--icon-height, 1em);
  background-color: var(--icon-color, currentColor);

  --icon-margin: 3px;
}

.icon-start::before {
  margin-right: var(--icon-margin);

  --icon-position: left;
}

.icon-end::after {
  margin-left: var(--icon-margin);

  --icon-position: right;
}

.icon-vertical-center {
  display: inline-flex;
  align-items: center;
}

/* Icon classes */

.icon-download {
  --icon-url: var(--icon-download);
}

.icon-ellipse{
  --icon-url: var(--icon-ellipse);
}
