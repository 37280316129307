.b-skip-to-content {
  white-space: nowrap;
  left: $space-sm;
  padding: $space-xs;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.15s;
  background-color: $white;
  z-index: 100;
  box-shadow: none;

  &:focus {
    transform: translateY(0%);
  }
}
