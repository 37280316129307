// For some reason default tailwind does not come with hdir tailwind so we
// recreate several default tailwind classes here

/** NOTES
- Tailwind does not add important so they are added manually as classes are used
- Backspace escapes following character allowing us to recreate classes like gap-2.5
*/

@layer base {
  .nt-h-7 { @apply h-[28px] }
  .nt-h-10 { @apply h-[40px] }

  .nt-w-7 { @apply w-[28px] }
  .nt-w-10 { @apply w-[40px] }

  .pl-0 { @apply pl-[0px]}
  .p-2 { @apply p-[8px]}
  .p-5 { @apply p-[20px] }
  .p-8 { @apply p-[32px] }
  .p-10 { @apply p-[40px] }

  .pt-3 { @apply pt-[12px] }
  .pt-4 { @apply pt-[16px] }

  .pb-7 { @apply pt-[28px] }
  .im-pb-7 { padding-bottom: 28px !important; }
  .pb-8 { @apply pt-[32px] }

  .px-2 { @apply px-[8px]}
  .px-4 { @apply px-[16px] }
  .px-5 { @apply px-[20px] }

  .py-4 { @apply py-[16px]}
  .py-6 { @apply py-[24px] }

  .mt-2\.5 { @apply mt-[10px] }
  .mt-3 { @apply mt-[12px] }
  .mt-4 { @apply mt-[16px] }
  .mt-5 { @apply mt-[20px] }
  .im-mt-5 { margin-top: 20px !important;}
  .mt-8 { @apply mt-[32px] }
  .im-mt-8 { margin-top: 32px !important;}

  .mb-4 {@apply mb-[16px]}
  .mb-5 {@apply mb-[20px]}
  .mb-8 { @apply mb-[32px] }
  .mb-16 { @apply mb-[64px] }
  .mb-20 { @apply mb-[80px] }

  .ml-4 {@apply ml-[16px]}

  .mx-3 { @apply mx-[12px] }

  .my-8 { @apply my-[32px]; }

  .space-y-2 { @apply space-y-[8px] }
  .space-y-4 { @apply space-y-[16px] }
  .space-y-6 { @apply space-y-[24px] }
  .space-y-10 { @apply space-y-[40px] }
  .space-y-12 { @apply space-y-[48px] }
  .space-y-16 { @apply space-y-[64px] }

  .text-4xl { @apply text-[36px]}
  .text-2xl { @apply text-[24px]}
  .text-xl { @apply text-[20px]}

  .font-normal { @apply font-[400]}

  .border-b-2 { @apply border-solid border-t-[0px] border-l-[0px] border-r-[0px] border-b-[2px];}
  .border-t-2 { @apply border-t-[2px]}
  .border-0{ border-width:0px}

  .list-none {list-style-type: none}
  .border-lightGray{ @apply border-[var(--color-gray-lightest)]}
}

@layer utilities {
  .gap-2\.5 { @apply gap-[10px] }
  .gap-3 { @apply gap-[12px] }
  .gap-4 { @apply gap-[16px] }
  .gap-10 { @apply gap-[40px] }

  .gap-x-6 { @apply gap-x-[24px] }
  .gap-x-8 { @apply gap-x-[32px] }
  .gap-x-10 { @apply gap-x-[40px] }
  .gap-x-16{ @apply gap-x-[64px] }
  .gap-x-24{ @apply gap-x-[96px] }

  .gap-y-6 { @apply gap-y-[24px] }
  .gap-y-3 { @apply gap-y-[12px] }

  .nt-ul-list{
    >li:first-child{
      border-top:2px solid var(--color-gray-lightest);
    }
  }
}

// For some reason tailwind screen queries do not work as desired.
// In example below @media (width > 1024px) is overwritten and is never applied
// Changed to custom class to accommodate
.action-grid-cols {
  grid-template-columns: repeat(1,auto);
  @media (width > 480px) { grid-template-columns: repeat(2,auto); }
  @media (width > 1024px) { grid-template-columns: repeat(3,auto); }
}

.action-hidden-or-block {
  display: none;
  @media (width > 640px) { display: block; }
}

.action-hidden-sm-inline {
  display: none;
  @media (width > 640px) { display: inline; }
}

.action-flex-col-sm-col {
  flex-direction: column;
  @media (width > 640px) { flex-direction: row; }
}

.text-ocean{
  color: var( --color-ocean) !important;
}

.hover-red {
  &:hover {
    color: var( --color-red) !important;
  }
}
.items-center{
  align-items: center;
}
.border-t-lightGray {
  border-top-color: var(--color-gray-lightest);
  border-top-style: solid;
  border-top-width: 1px;
}

.bg-green {
  background-color: var(--color-green);
}

.line-dashed-15 {
  @apply w-0 sm:w-0.5;

  background-image: linear-gradient(
    #0069e8 0%,
    #0069e8 25%,
    transparent 25%,
    transparent 75%,
    #0069e8 75%
  );
  background-size: 13px 13px;
}

.action {
  h1, h2, h3 {
    @apply font-normal
  }
}

.nt-tablist {
  list-style-type: none;
  padding-left: 0px;
  >li{
    a,
    a:where(:visited) {
      color: rgba(50, 50, 59) !important;
      box-shadow: none;
    }
  }

  >li>ul {
    padding-left: 0px;

    a,
      a:where(:visited) {
        color: rgba(50, 50, 59) !important;
        box-shadow: none;
      }
      &:first-of-type {
        border-top-width:2px;
      }
  }
}

#zoho-action-overview {
  p{
    margin:20px 0px;
    max-width: 55ch;
  }

  a,
    a:where(:visited) {
      color: #0667c6 !important;
      box-shadow: none;
    }
}
