.b-color-info {
  border-radius: 4px;
  border: 1px solid $grey-light;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;

  &__feature {
    color: white;
    width: 100%;
    height: 8rem;

    > img {
      width: 100%;
      display: block;
    }

    flex-shrink: 0;
  }

  &__content {
    display: block;
    height: 100%;
    position: relative;
    background-color: white;
    padding: 1rem;
    box-shadow: none;

    p {
      margin: 1em 0 0;
    }

    // Removes the margin, if there is only one child element
    > * {
      &:only-child {
        margin-top: 0;
      }
    }
  }

  &__heading {
    display: block;
    color: $black;
  }

  &__text {
    font-size: $meta-text;
    color: $grey;
    margin-top: 0.5em;
  }
}
