.b-presentation-link {
  padding-top: $space-sm;
  padding-bottom: $space-sm;
  display: flex;
  align-items: center;

  @include mq.mq($until: tablet) {
    padding-top: $space-xxs;
    padding-bottom: $space-xxs;
  }

  &__image {
    height: 2.5em;
    width: auto;
    margin-right: 0.75em;
  }

  &__text-wrapper {
    display: inline-block;
  }

  &__text {
    display: block;
    font-size: $font-xs;
  }

  &__subtext {
    margin-top: $space-sm;
    font-size: $font-xs;
  }
}
