.measure {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-20) var(--spacing-20);
  border-bottom:  10px solid var(--color-hdir-green-1);
  border-radius: var(--border-radius);
  background-color: var(--color-hdir-green-3);
  color: var(--color-gray-dark);
  text-decoration: none;
  gap: var(--spacing-10);
  transition: transform ease 200ms;

  &:hover,
  &:focus-visible {
    color: unset;
    text-decoration: none;

    h3 {
      text-decoration: underline;
    }
  }

  .measure-cta {
    --icon-url: var(--icon-arrow-right);

    color: var(--color-hdir-green-1);

    &::after {
      margin-left: var(--spacing-10);
      transition: transform ease 200ms;
    }
  }

  &:is(:hover, :focus-visible) .measure-cta::after {
    transform: translateX(var(--spacing-05));
  }
}
