.b-transport-block {
  display: block;
  padding: 1.5rem;
  margin: 0.1rem;
  box-shadow: 0 2px 8px $green;
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 7px 16px transparentize($green, 0.2);
    padding: 1.6rem;
    margin: 0;
    transition: box-shadow 0.3s, padding 0.3s, margin 0.3s, color 0.3s;
  }
}
