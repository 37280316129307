.b-icon-with-text {
  display: flex;
  align-items: center;

  @include mq.mq($until: tablet) {
    padding-top: $space-xxs;
    padding-bottom: $space-xxs;
  }

  &__image {
    height: 2.5em;
    width: auto;
    margin-right: 0.75em;

    &--small {
      height: 1em;
      margin-right: 0.6em;
    }

    &--align-top {
      align-self: start;
      padding-top: 0.3em;
    }
  }

  &__text-wrapper {
    display: inline-block;
  }

  &__text {
    display: block;
    font-size: $font-xs;
  }

  &--clean {
    .b-icon-with-text__text {
      text-transform: none;
      font-size: $medium-large-text;
    }
  }

  &__subtext {
    margin-top: $space-sm;
    font-size: $font-xs;
  }
}
