.pt-normen {
  display: flex;
  justify-content: center;
}

@media (width <= 1140px) {

  .pt-normen__content {
    box-sizing: border-box;
    width: 100%;
    padding: 0 40px;
  }
}

@media (width <= 767px) {

  .pt-normen__content {
    padding: 0 20px;
  }
}

.pt-normen__component {
  margin-right: auto;
  margin-left: auto;
}

.pt-normen__columns {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (width <= 1023px) {

  .pt-normen__columns {
    flex-direction: column;
  }
}

.pt-normen__column:first-child {
  margin-right: 75px;
}

@media (width <= 1070px) {

  .pt-normen__column:first-child {
    margin-right: 40px;
  }
}

@media (width <= 1023px) {

  .pt-normen__column:first-child {
    margin-right: 0;
  }
}

.pt-normen__column:last-child {
  margin-left: 75px;
}

@media (width <= 1070px) {

  .pt-normen__column:last-child {
    margin-left: 40px;
  }
}

@media (width <= 1023px) {

  .pt-normen__column:last-child {
    margin-left: 0;
  }
}

.pt-normen__signUpButton {
  width: 100%;
  margin-top: 10px;
}

.pt-normen__infoBoxHeader {
  margin-bottom: 10px;
  color: #281c2c;
  text-transform: uppercase;
}

.pt-normen__infoBoxDetail {
  margin-bottom: 20px;
}

.pt-normen__title {
  margin-bottom: 80px;
  padding-top: 60px;
}

@media (width <= 767px) {

  .pt-normen__title {
    margin-bottom: 40px;
  }
}

.pt-normen__ingress {
  margin-bottom: 60px;
}

.pt-normen__description h2 + p,
.pt-normen__description h2 + ul,
.pt-normen__description h3 + p,
.pt-normen__description h3 + ul,
.pt-normen__description h4 + p,
.pt-normen__description h4 + ul {
  margin-top: 0;
}

.pt-normen__description h2 {
  margin-top: 40px;
}

.pt-normen__description h2:first-child {
  margin-top: 0;
}

.pt-normen__lastUpdated {
  display: block;
  margin-top: 100px;
  margin-bottom: 20px;
}

.pt-normen__tags {
  margin-bottom: 60px;
}

@media (width <= 767px) {

  .pt-normen__tags {
    margin-bottom: 0;
  }
}
