.b-list-item-type {
  position: relative;
  width: 100%;
  width: calc(100% - 1.5rem);

  &::after {
    @include pseudo-arrow($arrow-right);

    position: absolute;
    right: -1rem;
    top: 0.4em;
  }

  &--center-arrow {
    &::after {
      top: calc(50% - 0.4rem);
    }
  }

  &--no-arrow {
    &::after {
      display: none;
    }
  }

  &--flex {
    display: flex;
  }

  &__download {
    font-size: $meta-text;
    text-decoration: underline;
    color: $link-color;
    margin-left: 1rem;
    margin-right: -1rem;
    padding-right: 1rem;
    flex-shrink: 0;
    position: relative;

    &::after {
      position: absolute;
      top: 0.4em;
      right: 0;

      @include pseudo-arrow($arrow-down);

      @include mq.mq($until: $tablet) {
        top: 0;
        position: relative;
      }
    }

    &--open::after {
      position: absolute;
      top: 0.4em;
      right: 0;

      @include pseudo-arrow($arrow-right);

      margin-right: -1rem;

      @include mq.mq($until: $tablet) {
        top: 0;
        position: relative;
      }
    }
  }

  &__current {
    font-size: $font-xs;
    font-weight: normal;
    margin-left: $space-sm;
    border: 0;
    border-radius: 20px;
    background-color: $yellow-light;
    padding: $space-xs/2 $space-sm;
  }

  &--link,
  &--link:link {
    text-decoration: none;
    color: $link-color;
  }

  &--float {
    display: inline-block;
    float: right;
  }

  &__email {
    display: inline-block;
    margin-top: 0.5rem;
  }

  &__meta {
    font-size: $meta-text;
    color: $grey;
  }

  &__topic {
    font-size: $meta-text;
    color: $grey;
    margin-top: 1em;
  }

  &__deadline {
    font-size: $meta-text;
    color: $red !important;
    margin-bottom: 0;
  }

  &__deadline--open {
    font-size: $meta-text;
    color: $green-warning !important;
  }

  &__lead {
    margin-bottom: 0;
  }

  &__date-circle {
    margin-right: $space-sm;
    background: $green-light;
    border-radius: 50%;
    height: 66px;
    width: 66px;
    font-size: $font-xs;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    flex-shrink: 0;

    @include mq.mq($from: tablet) {
      display: flex;
    }

    &--expired {
      background: $red-light;
    }

    &--generic {
      background: $grey-light;
    }

    &--active {
      background: $green-light;
    }
  }

  &__date-big {
    font-size: $font-l;
  }

  &__flex-wrapper {
    display: flex;
    align-items: center;

    @include mq.mq($until: tablet) {
      align-items: flex-start;
    }
  }

  &__image {
    flex: 1;
    max-width: 8rem;
    margin-right: 1rem;

    @include mq.mq($until: tablet) {
      display: none;
    }
  }

  &__text {
    flex: 3;
    padding-right: 0.5rem;

    h3 {
      font-size: $font-md;
    }
  }

  &__category {
    font-size: $meta-text;
    background-color: $yellow-light;
    display: inline-block;
    padding: 0.25em;
    margin: 1rem 0 0;
  }

  &__date {
    font-size: $font-xs;
    margin-top: 0.5em;
  }

  &__subheading {
    font-size: $font-xs;
    margin-top: 0.5em;

    >* {
      &:first-child {
        font-weight: bold;
      }

      &:only-child {
        font-weight: normal;
      }
    }
  }

  &__last-update {
    margin-top: $space-xs;
    display: flex;
    justify-content: flex-end;
  }

  &__icon-text {
    margin-top: $space-xs;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    font-size: $font-xs;
  }

  &__intro {
    margin-top: $space-xs;

    @media (width >= 40rem) {
      padding-right: 5rem;
    }

    @media (width >= 60rem) {
      padding-right: 8rem;
    }
  }
}
