.b-quote {
  display: block;
  font-size: $meta-text;
  padding: 1em;
  margin: 2em 0;
  background-color: $grey-lighter;

  // This is coming from the rich text-field in the CMS, so we need to make it non-BEM
  p {
    margin: 1em 0 !important;
    text-indent: 2em;
  }

  ul {
    list-style: none;
  }

  &__blockquote {
    &::after,
    &::before {
      content: '';
    }

    >div,
    ul {
      margin-bottom: 1em;
    }
  }

  &__source {
    text-align: right;
    font-weight: bold;
  }

  &__footnote {
    text-indent: 0 !important;
  }

  .legalP {
    text-indent: 0;
  }
}
