.sidebar-nav {
  --sidebar-nav-color-text: #53535C;
  --sidebar-nav-color-text-light: #727278;
  --color-primary: #0069e8;
  --color-border: #e5e5e5;
  --circle-radius: 4px;
  --indent-size: 20px;

  padding-bottom: 60px;
  font-size: 14px;

  & a[data-level] {
    display: flex;
    align-items: center;
    color: currentcolor;
    text-decoration: none;
    padding-block: 10px;

    &[aria-current="true"],
    &.in-path,
    &:hover {
      color: inherit;

      /* Fakes bold in a way that the text doesn't jump around because of size changes */
      text-shadow: -0.04ex 0 0 currentcolor, 0.04ex 0 0 currentcolor;
    }
  }

  & ul {
    margin-block: 0;
  }

  /* Remove bullet (TODO Fix issue globally where <li> is polluted) */

  & ul li::before {
    content: unset;
  }

  /* First level */

  > ul > li {
    border-top: 2px solid var(--color-border);

    &:last-child {
      border-bottom: 2px solid var(--color-border);
    }
  }

  @media (width >= 1023px) {

    & a[data-level="0"] {
      --icon-color: transparent;

      padding-block: 18px;
      justify-content: space-between;

      &::after {
        content: "";
        display: inline-block;
        flex-shrink: 0;
        width: 21px;
        height: 16px;
        margin-left: 10px;
        background-color: var(--icon-color);
        transition-duration: 100ms;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-image: url("../../assets/static/icons/arrow_right_hdir.svg");

      }

      &[aria-current="true"]::after,
      &.in-path::after {
        --icon-color: var(--color-primary);
      }

      &.in-path::after {
        transform: rotate(45deg);
      }
    }

    @supports selector(:has(+ *)) {

      > ul > li:has([aria-current="true"]) {
        border-block: 2px solid var(--color-primary);
      }

      > ul > li:has([aria-current="true"]) + li {
        border-top: none;
      }

      /* If there is an active level 3 heading rotate arrow 90deg */

      > ul > li:has([aria-current="true"]):has([data-level="2"][aria-current="true"]) a[data-level="0"].in-path::after {
        transform: rotate(90deg);
      }
    }
  }

  /* Second level */

  & a[data-level="1"] {
    color: var(--sidebar-nav-color-text-light);

    &::before {
      content: "";
      display: inline-block;
      flex-shrink: 0;
      width: var(--circle-radius);
      height: var(--circle-radius);
      margin-right: calc(var(--indent-size) - var(--circle-radius));
      border-radius: 100%;
      background-color: transparent;
    }

    &.in-path,
    &[aria-current="true"] {
      color: unset;

      &::before {
        background-color: var(--color-primary);
      }
    }
  }

  /* Third level */

  & a[data-level="2"] {
    color: var(--sidebar-nav-color-text-light);

    &::before {
      content: "";
      display: inline-block;
      flex-shrink: 0;
      width: 10px;
      height: 2px;
      margin-right: 10px;
      margin-left: var(--indent-size);
      background-color: var(--color-border);
    }

    &[aria-current="true"]::before {
      background-color: var(--color-primary);
    }
  }
}
