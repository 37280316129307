@layer base {
  .text-purple{ color: var(--color-border-purple); }
}

#downloadButton {
  text-decoration: underline;
  &:hover {
    color: rgba(50, 50, 59, 1);
  }
}

.text-bodyText {
  color: rgba(50, 50, 59, 1);
}

.pt-4 {
  padding-top: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.bg-boxPink {
  background-color: rgba(247, 234, 255, 1);
}

a.card {

  &.card-shadow {
    box-shadow: 0 0 40px 5px rgb(149 149 149 / 10%);
  }

  &.regulation-card{

    .card-image {

      & img {
        width: auto;
        max-height: 224px;
      }
    }

    .card-title {
      min-height: 60px;
    }
  }
}
