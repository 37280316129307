.cp-metaData {
  margin-top: 80px;
  margin-bottom: 80px;
}

@media (width <= 730px) {

  .cp-metaData {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.cp-metaData__wrapper {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  padding: 21px 10px;
  background-color: #e4f0f4;
}

@media (width <= 730px) {
  .cp-metaData__wrapper {
    flex-direction: column;
    padding: 20px;
  }
}

@media (width <= 992px) {
  .cp-metaData__wrapper {
    flex-direction: column;
    padding: 20px;
  }
}

.cp-metaData__data {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 18px;
  padding: 0 15px;
  color: #32323b;
  margin-right: 48px;
}

.cp-metaData__data:last-child {
  border-right: 0;
}

@media (width <= 730px) {
  .cp-metaData__data {
    width: 100%;
    height: 40px;
    border-right: 0;
  }
}

@media (width <= 992px) {
  .cp-metaData__data {
    width: 100%;
    height: 40px;
    border-right: 0;
  }
}

.cp-metaData__data a {
  color: #0056e8;
  text-decoration: underline;
}
