.b-flex {
  display: flex;
}

.b-flex--skip-mobile {
  @include mq.mq($until: $tablet) {
    display: block;

    > div {
      margin-bottom: $space-xs;
    }
  }
}

.b-flex--right {
  justify-content: flex-end;
}

.b-flex--center {
  align-items: center;
}
