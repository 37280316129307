.tabs {
  --tw-border-color: rgba(225, 225, 225);

  [role="tab"] {
    padding: 8px 16px;

    &:before {
      content: unset;
    }

    &[aria-selected="true"] {
      font-weight: 500;
      background-color: rgb(228, 240, 244);
    }

    &:focus-visible {
      outline: auto;
    }
  }

  .hidden {
    display: none;
  }

  >ul {
    list-style: none;
    padding-left: 0px;
    display: flex;
    flex-direction: column;

    @media (min-width: 640px) {
      flex-direction: row;
      border-bottom: 2px solid var(--tw-border-color);
    }

    >li {
      padding: 0.5rem;

      &:not(:first-of-type) {
        margin-top: -2px;
      }

      @media (min-width: 640px) {

        padding-left: 16px;
        padding-right: 16px;

        &:not(:first-of-type) {
          margin-top: 0;
          margin-left: -2px;
        }

        border-left: 2px solid var(--tw-border-color);
        border-top: 2px solid var(--tw-border-color);
        border-right: 2px solid var(--tw-border-color);
        border-bottom: 0;

        &:first-of-type {
          border-top-left-radius: 8px;
        }

        &:last-of-type {
          border-top-right-radius: 8px;
        }
      }
    }
  }

  a,
  a:where(:visited) {
    color: #0069e8 !important;
  }
}