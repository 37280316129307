.b-list-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grey-light;
  padding-bottom: $space-sm;

  @include mq.mq($until: tablet) {
    flex-direction: column;
  }
}
