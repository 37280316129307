$spacing-map: (
  xxs: 0.25rem,
  xs: 0.5rem,
  sm: 1rem,
  md: 1.5rem,
  lg: 2rem,
  xl: 4rem,
  xxl: 8rem
);

// This holds spacing for "old parts" (made with thymeleaf) used on themepages.
.spacing-themepage-parts { 
  padding: 32px 0;
}

.spacing-height {
  @each $spacingName, $spacingVal in $spacing-map {
    &--#{$spacingName} {
      height: $spacingVal;
    }
  }
}
