dialog{
  margin: auto;

  &::backdrop {
    background-color: rgb(40 28 44 / 33%);
  }
}

.p-component-sm {
  @apply p-5 pt-6 pb-7 md:p-8 md:pt-6 md:pb-10 xl:p-10 xl:pb-12;
}
