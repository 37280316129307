// Styles for the auto suggest component.
// The auto suggest is a react component from a package, which does not use BEM.
$spaceSmall: 0.75em;

.suggestion-title {
  display: inline-block;
  margin-top: 0.25rem;
  font-size: $medium-large-text;
}

.suggestion-topic {
  font-size: $meta-text;
  margin: 0.5rem 0;
}

.suggestion-intro {
  display: block;
  font-size: $meta-text;
  margin: 0.5rem 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.suggestion__meta {
  font-size: $meta-text;
}

.suggestion-category {
  display: inline-block;
  margin-left: 0.5em;
  font-size: $meta-text;
  background-color: $yellow-light;
  padding: $space-xs/2 $space-sm;
  border: 0;
  border-radius: 20px;
}

.suggestion-download {
  display: inline-block;
  margin-left: 1em;
  font-size: $meta-text;

  @include underline($link-color);
}
