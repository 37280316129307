@layer base {
  // TODO: Used to counteract overwrite from helsecore tailwind. Can be removed when npm-helsecore is implemented
  .nt-block { display: block; }
}

@layer components {
  .contentFooter-border-left {
    border-left: 1px solid var(--design-color-text-neutral-subtle);
  }

  .contentFooter-border-bottom {
    border-bottom: 1px solid var(--design-color-border-action-tertiary-default);
  }
}
