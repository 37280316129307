.link-list-nav {
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 2px solid var(--color-gray-lightest);
    color: inherit;
    text-decoration: none;
    gap: var(--spacing-20);
    padding-block: var(--spacing-20);

    &:last-child {
      border-bottom: 2px solid var(--color-gray-lightest);
    }

    .link-list-nav-text {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-20);
      text-wrap: balance;
    }

    .link-list-nav-title {
      font-weight: var(--font-weight-medium);
    }

    &:hover .link-list-nav-title {
      text-decoration: underline;
    }

    .link-list-nav-intro {
      color: var(--color-gray-light);
    }

    .link-list-nav-icon {
      display: flex;
      align-items: center;
      min-width: 20px;
      color: var(--color-blue);
      transition: transform 200ms;
    }

    &:hover .link-list-nav-icon {
      transform: translateX(var(--spacing-05));
    }
  }
}
