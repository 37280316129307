a.card {
  --card-border-radius: 16px;
  --card-background-color: transparent;
  --card-background-color-hover: transparent;
  --card-padding: var(--spacing-40);
  --icon-url: var(--icon-arrow-long);
  --icon-width: 61px;
  --icon-height: 16px;
  --icon-color: var(--color-primary);

  display: flex;
  flex-direction: var(--card-flex-direction);
  padding: var(--card-padding);
  gap: var(--spacing-40);
  border-radius: var(--card-border-radius);
  background-color: var(--card-background-color);
  color: currentcolor;
  text-decoration: none;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1), background-color 200ms linear;

  &:focus,
  &:hover {
    background-color: var(--card-background-color-hover);
    color: currentcolor;
    text-decoration: none;
    transform: scale(1.05);
  }

  .card-text {
    display: flex;
    flex: 3;
    flex-direction: column;
    gap: var(--spacing-20);
  }

  .card-image {
    flex: 1;

    & img {
      max-width: min(var(--image-max-width), 100%);
      margin-right: var(--image-margin-right, auto);
      margin-left: var(--image-margin-left, auto);
    }
  }

  &.card-image-position-left {
    --card-flex-direction: row-reverse;
    --image-max-width: 200px;
    --image-margin-left: 0;
  }

  &.card-image-position-right {
    --card-flex-direction: row;
    --image-max-width: 200px;
    --image-margin-right: 0;
  }

  &.card-image-position-top {
    --card-flex-direction: column-reverse;
    --card-align-image: center;
    --image-max-width: 256px;
  }

  &.card-image-position-bottom {
    --card-flex-direction: column;
    --card-align-image: center;
    --image-max-width: 256px;
  }

  &.card-image-position-none {
    --card-flex-direction: row;
    --card-align-image: center;

    & svg {
      align-self: var(--card-align-image);
    }
  }

  &.card-color-blue {
    --card-background-color: var(--color-bg-blue);
    --card-background-color-hover: #b3ebf2;
    --icon-color: var(--color-blue);
  }

  &.card-color-purple {
    --card-background-color: var(--color-bg-purple);
    --card-background-color-hover: #f0d9ff;
    --icon-color: var(--color-purple);
  }

  &.card-color-green {
    --card-background-color: var(--color-bg-green);
    --card-background-color-hover: #c6fad2;
    --icon-color: var(--color-blue);
  }

  &.card-half-padding {
    --card-padding: var(--spacing-20);
  }
}
