.b-loading {
  background-color: rgb(255 255 255 / 40%);
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;

  &.active {
    display: flex;
  }

  &__content {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 15px;
    background-color: white;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 11;
  }

  &__spinner {
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
