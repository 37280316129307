.b-list {
  &--no-style {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &--contacts {
    width: 100%;

    & > :not(:last-child) {
      padding-bottom: $space-xs;
    }
  }

  &--columns {
    &-2, &-3 {
      column-gap: 1rem;
    }

    @media (width >= 40rem) {
      &-2, &-3 {
        columns: 2;
      }
    }

    @media (width >= 60rem) {
      &-3 {
        columns: 3;
      }
    }
  }

  &__item {
    margin: $space-xs 0;

    &--mb {
      margin: 0 0 $space-sm 0;
    }
  }

  &__description > * {
    margin-top: 0;
    margin-bottom: 0;
  }
}
