.react-tabs {
  -webkit-tap-highlight-color: transparent;
  margin-top: $space-lg;

  &__tab-list {
    border-bottom: 1px solid $blue-dark;
    margin: 0 0 $space-sm;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: $space-xs $space-sm;
    cursor: pointer;

    @include mq.mq($until: $tablet) {
      font-size: $font-xs;
      padding: $space-xs;
    }

    &--selected {
      background: #fff;
      border-color: $blue-dark;
      color: black;
      border-radius: 5px 5px 0 0;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208deg 99% 50%);
      border-color: hsl(208deg 99% 50%);
      outline: none;

      &::after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  &__tab-count {
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-left: $space-xs;
    text-align: center;

    &--green {
      background: $green-light;
      border: 1px solid rgba($green-dark, .2);
    }

    &--red {
      background: $red-light;
      border: 1px solid rgba($red-dark, .2);
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
