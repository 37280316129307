.b-meta-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: $space-xs 0;

  @include mq.mq($until: $tablet) {
    flex-flow: column nowrap;
  }
}

.b-meta-list__item {
  font-size: $font-xs;
  display: flex;
  margin: 0 $space-md $space-xs 0;
  align-items: flex-start;

  @include mq.mq($until: $tablet) {
    margin: 0 $space-xs $space-xs 0;
  }

  &--separate {
    flex-basis: 100%;
    font-weight: 600;
    margin: 0 $space-lg $space-sm 0;
    margin-top: $space-md;
    margin-bottom: $space-xs;

    @include mq.mq($until: $tablet) {
      margin-top: $space-xs
    }
  }
}

.b-meta-list__icon {
  height: 1.4em;
  width: 1.2em;
  display: inline-block;
  margin-right: $space-xs;
  padding-top: $space-xs / 4;

  &--clock {
    background: url('../static/icons/clock.svg');
    background-repeat: no-repeat;
    background-position: 0 4px;
    background-size: 95%;
  }

  &--calendar {
    background: url('../static/icons/calendar.svg');
    background-repeat: no-repeat;
    background-position: 0 4px;
    background-size: 100%;
  }

  &--map-pin {
    background: url('../static/icons/map-pin.svg');
    background-repeat: no-repeat;
    background-position: -1px 4px;
    background-size: 18px;

    @include mq.mq($until: $tablet) {
      flex-basis: 15%;
      max-width: 18px;
      min-width: 16px;
    }
  }

  &--target-group {
    background: url('../static/icons/target-group.svg');
    background-repeat: no-repeat;
    background-position: 0 4px;
    background-size: 100%;
  }

  &--info-black {
    background: url('../static/icons/info_black.svg');
    background-repeat: no-repeat;
    background-position: 0 4px;
    background-size: 100%;

    @include mq.mq($until: $tablet) {
      flex-basis: 15%;
      max-width: 18px;
      min-width: 16px;
    }
  }

  &--large {
    width: 2.1em;
    height: 1.8em;
    background-position: 0;
    margin-top: -2px;
    background-size: contain;
  }
}
