.b-theme-box {
  @include mq.mq($until: $tablet) {
    padding: $space-md $space-sm;
  }

  @include mq.mq($from: $tablet) {
    padding-top: $space-xl;
    padding-bottom: $space-xl;
  }
}

.front .b-theme-box-wrapper:first-child {
  margin-top: -$space-md !important;
}


// When used inside layouts (not front page), remove bg color
.l-container .b-box--blue-light {
  background: transparent;
}
