.b-box {
  border: 1px solid $grey-light;
  border-radius: 4px;
  padding: 2rem;
  background-color: $grey-lighter;

  @include mq.mq($until: $tablet) {
    padding: 1rem;
  }

  &--square {
    border-radius: 0;
    border: 0 !important;
  }

  &--no-border-radius {
    border-radius: 0;
  }

  &--no-padding {
    padding: 0;
  }

  &--tiny-padding {
    padding: 1rem;
  }

  &--transparent {
    background: transparent;
  }

  &--green {
    background-color: $green-light;
    border: 1px solid $green;
  }

  &--white {
    background-color: white;
    border: 1px solid $grey-light;
  }

  &--blue {
    background-color: $blue-lighter;
    border: 1px solid $blue-light;

    @include mq.mq($until: $tablet) {
      padding: 1rem 1.5rem;
    }
  }

  &--blue-light {
    background-color: $blue-lightest;
  }

  &--blue-dark {
    color: $purple !important;
    background-color: $blue-dark;
    border: 1px solid $blue-light;
  }

  &--yellow {
    color: $black !important;
    background-color: $yellow-light;
  }

  &--blue-border {
    background-color: white;
    border: 1px solid $primary-color-light;
  }

  &--stacked {
    margin-top: -1px;
  }
}
