.nt-link {
  & a {
    color: #0069e8 !important;
  }
}

.nt-header-title {
  font-weight: 400;
}

@layer base{
  .nt-flex-col{
    @apply flex-col;
  }
}
