.b-table {
  &__full-screen {
    inset: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey;
    opacity: 1;

    &-container {
      width: 90%;
      padding: 0.5rem;
    }

    &-close-button {
      color: $white;
      margin-bottom: 0;
      margin-left: auto;

      // using important because some styles set display: none to buttons (?)
      display: block !important;
      font-size: $font-l;
      align-items: center;
      column-gap: 0.5rem;

      &:hover {
        color: $white;
      }
    }

    &-open-button {
      margin: 0;
      padding: 0;

      // using important because some styles set display: none to buttons (?)
      display: flex !important;
      align-items: center;
      column-gap: 0.5rem;

      svg {
        color: $blue-dark;
        background-color: transparent;
      }
    }

  }
}

.b-table__wrapper {
  position: relative;
  overflow-x: auto;
  $borderStyles: 1px solid $grey-light;
  background-image:
    /* Shadows */
    linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),

    /* Shadow covers */
    linear-gradient(to right, rgb(0 0 0 / 25%), rgb(255 255 255 / 0%)),
    linear-gradient(to left, rgb(0 0 0 / 25%), rgb(255 255 255 / 0%));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 101%, 20px 101%, 10px 101%, 10px 101%;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  margin: $space-md 0;

  // Add vertical scroll when tall table
  max-height: 80vh;

  table {
    font-size: $font-xs;
    border: $borderStyles;
    min-width: 100%;
    min-height: 100%;

    @include mq.mq($until: $tablet) {
      border: 0;
    }

    p {
      margin: 0;
    }
  }

  // Class is added via vanilla-js
  .is-number {
    word-break: keep-all;
    white-space: nowrap;
    text-align: right;
  }

  caption {
    text-align: left;
    padding: $space-sm;
    background: white;

    @include mq.mq($until: $tablet) {
      padding: $space-sm 0 $space-sm $space-sm;
    }
  }

  td,
  table td:first-child {
    padding: $space-xs $space-sm;
    border: 0;
    border-top: $borderStyles;
    border-bottom: $borderStyles;
    z-index: 1;
  }

  thead td {
    @include mq.mq($until: $tablet) {
      box-shadow: 0 3px 5px rgba(128, 128, 128, 0.3);
    }

    background: #f6f6f6;
    position: sticky;
    top: 0;
    left: 0;
  }

  table th {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background: #f6f6f6;

    @include mq.mq($until: $tablet) {
      box-shadow: 0 3px 5px rgba(128, 128, 128, 0.3);
    }
  }

  thead td,
  th,
  table th:first-child {
    padding: $space-xs $space-sm;
    font-weight: 700;
    border-top: $borderStyles;
    border-bottom: $borderStyles;
    left: -1px;
  }

  th,
  table th:first-child {
    border: $borderStyles;
    width: auto;
  }

  tr:nth-child(even) td {
    @include mq.mq($from: $tablet) {
      background: rgba($grey, .05);
    }
  }

  table tr:last-child td {
    border-top: 0;
    border-bottom: $borderStyles;
  }
}

.b-table__wrapper--typography {
  table {
    /* Reset font size for typography tables to display font sizes as they are outside tables */
    font-size: $font-body;
  }
}

.b-table__cell--full {
  width: 100%;
}
