.b-related-statistics-list {
  padding: 0;

  @media (width >= 40rem) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 $space-sm;
  }

  &__item {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid $blue;

    &:last-child {
      border-bottom: 1px solid $blue;
    }

    @media (width >= 40rem) {
      &:nth-last-child(2) {
        border-bottom: 1px solid $blue;
      }
    }
  }

  &__link {
    display: block;
    box-shadow: none;
    padding: $space-sm;
    
    &:hover {
      background: $blue-lighter;
      color: inherit
    }
  }
}
