.b-icon {
  &--heading {
    float: left;
    margin-right: 0.5em;
    height: 1em;
    width: auto;
  }

  &--small {
    height: 1em;
    width: auto;
    margin-bottom: -0.05em;
  }

  &--medium {
    height: 2em;
    width: auto;
    margin-bottom: -0.6em;
  }

  &--large {
    height: 3rem;
    width: auto;
  }

  &--category {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      box-shadow: none;
    }

    &__icon {
      height: 3em;
      width: auto;
      flex-shrink: 0;
      margin-right: 1em;
    }

    &__heading {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.b-icon__category {
  font-size: $font-xs;
  display: inline-block;
}
