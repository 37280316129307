.b-radio-button-group {
  display: inline-block;
  position: relative;
  border: 0;
  padding: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-before: 0;
  -webkit-padding-end: 0;
  -webkit-padding-start: 0;
  -webkit-padding-after: 0;

  &--nested {
    border-left: 3px solid $grey-light;
    margin: 0.66rem;
    padding-left: $space-sm;
  }

  &__legend {
    font-weight: bold;
    padding: 0;
    margin: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;

    &--border {
      width: 100%;
      padding-bottom: calc(#{map-get($space-map, xs)} - 7px);
      border-bottom: 4px solid $black;
      margin-bottom: calc(#{map-get($space-map, xs)} - 7px);
    }
  }

  &__input {
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    vertical-align: middle;

    &:disabled + .b-radio-button-group__indicator {
      background: $grey-light;
      border-color: $grey !important;
      cursor: not-allowed;
    }

    &:hover + .b-radio-button-group__indicator {
      border-color: $secondary-color;
    }

    &:checked + .b-radio-button-group__indicator {
      border-color: $secondary-color;

      &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        background: $secondary-color;
        transition: background 0.3s ease;
      }
    }
  }

  &__label {
    display: flex;
    align-content: flex-start;
    margin-top: 0.5rem;
    font-size: $meta-text;
    cursor: pointer;

    @include focus-visible;
  }

  &__indicator {
    flex-shrink: 0;
    position: relative;
    margin-top: 0;
    margin-right: 0.5rem;
    border: 2px solid $grey;
    border-radius: 50%;
    background: white;
    width: 22px;
    height: 22px;
    transition: border-color 0.3s ease, max-height 0.3s ease, transform 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background: transparent;
    }
  }
}
