.b-narrow-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1em;
  }
}

.b-narrow-list__item {
  padding-left: 1.6rem;
  position: relative;
  line-height: 1.1rem;
  margin: 5px 0;

  &::before {
    content: '–';
    display: inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 3px;
  }
}
