// TODO: Remove all variables that in the future can be replaced by either designsystem or tailwind

/*
** Variables used for both HDIR and Helfo
*/

// Base font
$base-font-weight: normal;
$base-font-family: 'Work Sans', sans-serif;
$base-line-height: 1.6;
$text-color: $black;

/* Typography */
$font-body: 16px;
$font-body-mobile: 16px;
$font-h1: 36px;
$font-h1-mobile: 24px;
$font-h2: 24px;
$font-h2-mobile: 21px;
$font-h3: 20px;
$font-h3-mobile: 19px;
$font-h4: 18px;
$font-h4-mobile: 17px;
$font-h5: 16px;
$font-h5-mobile: 16px;
$font-xxl: $font-h1;
$font-xxl-mobile: $font-h1-mobile;
$font-xl: $font-h2;
$font-xl-mobile: $font-h2-mobile;
$font-l: $font-h3;
$font-l-mobile: $font-h3-mobile;
$font-md: $font-h4;
$font-md-mobile: $font-h4-mobile;
$font-sm: $font-h5;
$font-sm-mobile: $font-h5-mobile;
$font-xs: 14px;
$font-xs-mobile: 14px;

// DEPRECATED Font sizes
$extra-large-text: $font-xxl;
$large-text: $font-l;
$medium-large-text: $font-md;
$meta-text: $font-sm;

/* Spacing */
$space-xxs: 0.25rem;
$space-xs: 0.5rem;
$space-sm: 1rem;
$space-md: 1.5rem;
$space-lg: 2rem;
$space-xl: 4rem;
$space-xxl: 8rem;

// Layout
$container-small: 4vw;
$container-medium: 6vw;
$container-large: 14vw;

// Icons
$arrow-white-down: '../static/icons/arrow_down_white.svg';
$arrow-white-right: '../static/icons/arrow_right_white.svg';
$search: '../static/icons/search.svg';
$search-white: '../static/icons/search_white.svg';
$hamburger: '../static/icons/hamburger.svg';
$hamburger-white: '../static/icons/hamburger_white.svg';
$hamburger-close: '../static/icons/hamburger_close.svg';
$hamburger-close-white: '../static/icons/hamburger_close_white.svg';
