.b-multi-selector {
  position: relative;

  &__select {
    display: flex;
    background: none;
    border: 0;
    font-size: $meta-text;
    border-bottom: 1px solid $black;
    padding: 0 0.4em 0.25em 0.1em;
    cursor: pointer;
    text-transform: uppercase;

    &:active {
      color: $black;
    }

    &::before {
      display: block;
      content: ' ';
      margin-top: 0.2em;
      margin-right: 0.5em;
      width: 0.75em;
      height: 0.75em;
      transform: rotate(90deg);
      background-image: url("../static/icons/chevron.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--open {
      border-color: transparent;

      &::before {
        margin-top: 0.1em;
        transform: rotate(180deg);
      }
    }
  }

  &__box {
    top: 1.25em;
    position: absolute;
    z-index: 5;
    background-color: white;
    display: inline-block;
    border-top: 2px solid $primary-color;
    box-shadow: 0 3px 4px rgba($green-dark, 0.4);
  }

  &__checkboxes {
    padding: 1rem 2rem 1rem 1rem;
    max-height: 15rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 10px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid white;
      background-color: rgb(0 0 0 / 60%);
    }
  }

  &__buttons {
    display: flex;
    align-content: space-around;
    padding: 1rem;
    border-top: 1px solid $grey-light;
  }
}
