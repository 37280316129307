.b-chapter-hero {
  &__heading {
    display: flex;
    flex-direction: column;
  }

  & > .row {
    margin-left: 0;
    margin-right: 0;
  }

  &__kicker {
    font-size: $font-sm;
    font-weight: 600;
  }

  &__lead-container {
    padding: 1rem;
  }

  &__lead {
    max-width: 45ch;
    font-size: $font-md;
    font-weight: 600;
    width: 100%;
  }

  &__image {
    max-height: 20rem;
    max-width: 20rem;
    width: 100%;
    height: 100%;
  }

}

.pink-box {
  margin-top: 2rem;
  padding: 2rem;
  background-color: rgb(255 234 237);
  font-size: $font-l;

  & * {
    list-style: none;
  }
}
