.b-square-image {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;

  &__background {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    filter: blur(100px) saturate(50);
    opacity: 0.5;
    z-index: 1;

    // Performance optimizations
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }

  &__image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    text-align: center;
  }

  &__image {
    content: ' ';
    height: 90%;
    width: 90%;
    margin: 5%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
