// These styles are also used in the List component.

.b-nav-list {
  &__heading {
    border-bottom: 1px solid $primary-color;
    padding-bottom: 0.25rem;
    font-weight: bold;
    line-height: $base-line-height; // this is a reset
    display: flex;
    align-items: center;
  }

  &__items {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &--columns {
      @include mq.mq($from: $desktop) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  &__item {
    border-bottom: 1px solid $primary-color;

    &--column {
      @include mq.mq($from: $desktop) {
        flex: 0 47.5%;
        box-sizing: border-box;
      }
    }

    &--blue {
      border-left: 0.25em solid $blue-light;
      padding-left: 1.5em;
    }

    &--green {
      border-left: 0.25em solid $green;
      padding-left: 1.5em;
    }

    &--orange {
      border-left: 0.25em solid $orange;
      padding-left: 1.5em;
    }

    &--yellow {
      border-left: 0.25em solid $yellow;
      padding-left: 1.5em;
    }

    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        .b-nav-list__link--ordered {
          &::before {
            display: block;
            content: '#{$i}';
            padding-right: 1em;
            color: $secondary-color;
          }
        }
      }
    }
  }

  &__item-info {
    font-size: $meta-text;
    padding-right: 1.5rem;
    flex-shrink: 0;

    @include mq.mq($until: $tablet) {
      width: calc(100% - 2em);
      padding: 0;
    }

    &--highlight {
      display: inline;
      height: 1.5em;
      background-color: $yellow-light;
      margin-right: 2em;
      margin-top: -0.25em;
      padding: 0.25em;
    }

    &--file {
      position: relative;
      color: $black;
      padding-right: 1rem;
      padding-left: 0;

      @include underline($link-color);

      height: 1.4em;
      cursor: pointer;

      &::after {
        position: absolute;
        top: 0.4em;
        right: 0;

        @include pseudo-arrow($arrow-down);

        @include mq.mq($until: $tablet) {
          top: 0;
          position: relative;
        }
      }

      @include mq.mq($until: $tablet) {
        @include underline(transparent);
      }
    }
  }

  &__item-meta {
    font-size: $font-xs;
    margin-right: 2rem;
    color: $grey;

    &--hide {
      display: none;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    text-align: left;
    position: relative;

    // padding: 1rem; margin: 0 -1rem; // Experimental
    cursor: pointer;

    @include mq.mq($until: $tablet) {
      flex-direction: column;
      padding: $space-sm 0;
    }

    @include link-init($black);

    padding: $space-sm*.8;

    &:hover {
      &[href] {
        color: $link-color;
        background: $primary-color-lightest;
      }
    }

    &--active {
      cursor: default;
      background-color: rgba(
        $yellow,
        0.15
      ) !important; // We use important here, because we don't have the hover state to override it.

      transition: background-color 0.3s;

      &:hover {
        color: $black !important;
      }
    }

    &--no-arrow {
      padding-right: 1rem !important;

      &::before {
        display: none !important;
      }

      &::after {
        display: none !important;
      }
    }

    &--arrow {
      padding-right: 2rem;

      &::after {
        @include pseudo-arrow($arrow-right);

        top: 1.35em;
        position: absolute;
        right: $space-sm;
      }
    }

    &--no-pointer {
      cursor: default;

      .b-list-item-type::after {
        display:none;
      }
    }

    // DEPRECATED - might be used in Enonic somewhere.
    // Grep the Enonic project for .b-nav-list__link--anchor before removing.
    &--anchor {
      justify-content: flex-start;

      &::before {
        content: '↓';
        color: $primary-color;
        display: inline-block;
        margin-left: 0;
        padding-right: 0.75rem;
      }

      &::after {
        // We use this as a reset, to remove the other arrow
        content: '';
      }

      @include mq.mq($until: $tablet) {
        margin-left: 1rem;
        position: relative;

        &::before {
          position: absolute;
          top: 1rem;
          left: -0.5rem;
        }
      }
    }
  }

  &__item-description {
    min-width: 15%;
    text-transform: uppercase;
    flex-shrink: 0;

    @include mq.mq($until: $tablet) {
      width: calc(100% - 2em);
    }
  }

  &__item-title {
    width: 100%;
    padding-right: 1rem;

    @include mq.mq($until: $tablet) {
      padding-right: 0;
      width: calc(100% - 2em);
    }
  }

  &--small {
    font-size: $font-xs;
  }
}
