.b-secondary-logos {
  display: flex;
  flex-wrap: wrap;

  @include mq.mq($until: $tablet) {
    display: block;
  }

  &__logolist {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    align-items: flex-start;
    width: fit-content;
    max-width: 50%;

    @include mq.mq($until: $tablet) {
      max-width: 100%;
    }

    img {
      margin-right: .5em;
      width: 100px;

      &:last-child {
        margin-right: 1em;
      }
    }
  }

  &__text {
    font-size: $font-xs;
    min-width: 300px;
    flex-basis: 300px;
    flex-grow: 1;
    margin-bottom: 1em;
    border-left: 1px solid $grey;
    align-items: center;
    display: flex;

    p {
      padding-left: 1em;
      margin: 0;
    }
  }
}
