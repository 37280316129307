.b-press-release {
  display: flex;
  padding: 1rem 0;

  &:hover {
    .b-press-release__title {
      color: $link-color;

      @include underline($link-color);
    }
  }

  &__image-wrapper {
    min-width: calc(33% - 1rem);
    margin-right: 1rem;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__info {
    text-transform: uppercase;
    font-size: $meta-text;
    color: $grey;
  }

  &__text-wrapper {
    flex-grow: 1;
  }

  &__title {
    color: $black;
    display: inline;
  }

  &__release-date {
    color: $grey;
    font-size: $meta-text;
  }
}
