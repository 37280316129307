.b-ninja {
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: $space-lg;
  }

  .b-collapsible__heading {
    margin: 0;
  }
}
