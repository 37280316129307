.b-toggle-content button {
  display: none;
}

.b-toggle-content.toggle-mode button {
  display: inline-block;
}

.b-toggle-content .b-definition__button {
  display: inline-block !important;
}

.b-toggle-content__text {
  word-break: break-word;

  table {
    word-break: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5em;

    &:focus {
      outline: 0;
      color: $primary-color;
    }
  }

  // Removes the top margin from the first heading
  > :first-child {
    margin-top: 0;
  }
}

.toggle-mode .b-toggle-content__text {
  position: relative;
  height: 100px;
  overflow-y: hidden;

  &::after {
    content:'';
    width:100%;
    height:100px;
    position:absolute;
    left:0;
    bottom:0;
    background-image:linear-gradient(to top, $white, rgb(255 255 255 / 0%));
    pointer-events: none;
    z-index: 9;
  }

  p:first-child {
    margin-top: 0;
  }
}

.b-toggle-content.show {
  .b-toggle-content__text {
    height: auto;
    overflow-y: visible;

    &::after {
      display: none;
    }
  }
}
