.b-transport-module {
  box-sizing: border-box;

  &--helfo {
    padding: 1rem
  }

  &--bg-blue-light {
    padding: 2rem;
    background-color: $blue-lighter-2;
  }

  &--bg-purple-light {
    @if variable-exists($name: purple-lightest) {
      background-color: $purple-lightest;
    }
    @else {
      background-color: $purple;
    }
  }

  &--bg-grey-light {
    background-color: $grey-lighter;
  }

  // adds proper spacing between elements without
  // having to set it explicitly on each one
  & > * + * {
    margin-top: 1em;
  }

  &__heading {
    &-icon {
      max-width: 1.5rem;
      max-height: 1.5rem;
      margin-right: 0.5rem;
      color: $primary-color;
    }
  }

  &__list {
    list-style-type: none;
    padding-inline-start: 0;

    // this compensates for the padding on the boxes messing with alignment
    margin-left: -1rem;
    margin-right: -1rem;

    &--helfo {
      box-sizing: border-box;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__list-item {
    padding: 1rem;

    &--helfo {
      padding: 0.5rem;

      @include mq.mq($until: 560px) {
        width: 100%;
      }
    }

    &-content {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;


      & > * + * {
        margin-top: 0.5em;
      }

      &--helfo {
        box-sizing: border-box;
        background-color: $white;
        padding: 0.75rem;
      }

      &--hdir {
        border-radius: 0.5rem;
        overflow: hidden;
        border: 1px solid #d8dada;
        background: #fff;
      }

    }

    &-img {
      $imageHeight: 15rem;
      width: 100%;
      height: clamp($imageHeight * 0.8, 40vw, $imageHeight);
      object-fit: cover;
    }

    &-heading {
      &--helfo {
        display: flex;
        justify-content: space-between;
        align-items: start;
        width: 100%;

        svg,
        img {
          margin-top: 0.4rem;
          margin-left: 0.5rem;
          min-width: 0.8rem;
        }
      }
    }

    &-text {
      & > * + * {
        margin-top: 0.5em;
      }

      &--hdir {
        flex: 1 1 auto;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
      }
    }

    &-arrow {
      &--hdir {
        margin-top: auto;
        color: #025169;
        display: flex;
        justify-content: end;
      }
    }
  }
}
