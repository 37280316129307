.b-grant-byline {
  @include mq.mq($from: $tablet) {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    max-width: 800px;

    @include mq.mq($from: $tablet) {
      margin-right: 4em;
    }
  }

  &__status-icon {
    display: inline-block;
    background-color: $green;
    width: 1.1em;
    height: 1.1em;
    border-radius: 100%;
    margin-right: 0.5em;
    vertical-align: text-top;
  }

  &__status-icon--expired {
    background-color: $red;
  }

  &__amount-icon {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
    vertical-align: middle;
  }
}
