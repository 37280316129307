@mixin link-init($color, $underline: false, $underline-color: $color) {
  &:link,
  &:hover,
  &:visited,
  &:active {
    color: $color;
    box-shadow: none;
    border-bottom: 0;
    text-decoration: none;

    @if $underline == true {
      box-shadow: 0 1px 0 $underline-color;
    }
  }
}

@mixin underline($color) {
  box-shadow: 0 1px 0 $color;
}

@mixin focus-visible($color: $link-color) {
  &:focus-visible {
    outline: 3px solid $color;
    outline-offset: 2px;
  }
}

@mixin pseudo-arrow($direction) {
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin-left: 0.4em;
  content: '';
  background-image: url($direction);
  background-size: 0.8em;
  background-repeat: no-repeat;
}

@mixin chevron($variant) {
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin-left: 0.4em;
  content: '';
  background-image: url($variant);
  background-size: 0.8em;
  background-repeat: no-repeat;
  color: $primary-color;
}

@mixin icon-before($svg) {
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  margin-right: 0.5em;
  content: '';
  background-image: url($svg);
  background-size: 0.8em;
  background-repeat: no-repeat;
}
