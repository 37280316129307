.b-article-intro {
  position: relative;

  @include mq.mq($until: $tablet) {
    flex-direction: column;
  }

  &__image {
    // This applies both on a 'img' tag and a 'div' tag with background
    max-width: 100%;
    max-height: 370px;

    &-wrapper--feature & {
      // This applies only on a 'div' tag with background
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      object-fit: cover;
      background-size: cover;
      background-position: 50% 50%;
    }

    @include mq.mq($until: $tablet) {
      width: 100%;
    }
  }

  &--full {
    .b-article-intro__image-wrapper, .b-article-intro__text {
      width: 100%;
    }
  }

  // When used on theme pages etc. we want the image to float to the right of the title/intro
  &--inline-image {
    @include mq.mq($from: $desktop) {
      display: flex;
      flex-direction: row-reverse;

      .b-article-intro__image-wrapper{
        flex-basis: 40%;
        margin-top: 0;

        .b-article-intro__image-text {
          margin-bottom: 0;
        }
      }

      .b-article-intro__content-wrapper {
        flex-basis: 60%;
        padding-right: 3em;
        flex-grow: 1;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }

  &__image-wrapper {
    // This wraps both a 'img' and a 'div' tag with background
    margin-top: 1.5em;

    &--feature & {
      // This wraps only a 'div' tag with background
      max-height: auto;
    }

    @include mq.mq($until: $tablet) {
      width: 100%;
      margin-left: 0;
    }

    &--feature {
      flex-shrink: 0;

      @include mq.mq($until: $tablet) {
        width: 100%;
        margin-left: 0;
      }

      @include mq.mq($until: $desktop) {
        width: calc(100% + #{$container-small} + #{$container-small});
        margin-left: -$container-small;
      }

      @include mq.mq($from: $desktop) {
        height: 60vh;
        width: calc(100% + #{$container-medium} + #{$container-medium});
        margin-left: -$container-medium;
      }

      @include mq.mq($from: $wide) {
        width: calc(100% + #{$container-large} + #{$container-large});
        margin-left: -$container-large;
      }

      + .b-article-intro__intro {
        margin-top: 6vw;
      }
    }
  }

  &__image-text {
    margin: 0.5rem 0 1rem;
    color: $grey;
    font-size: $font-xs;

    @include mq.mq($until: $tablet) {
      font-size: $font-xs-mobile;
    }
  }

  &__intro {
    font-size: $font-l;
    max-width: 700px;
    margin-bottom: $space-md;

    @include mq.mq($until: $tablet) {
      font-size: $font-l-mobile;
    }
  }

  &__topic {
    font-weight: normal;
    font-size: $base-font-size;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  &__text {
    @include mq.mq($until: $tablet) {
      width: 100%;
      flex-shrink: 0;
    }

    h1 {
      max-width: 700px;
    }

    &--feature {
      position: absolute;
      box-sizing: border-box;
      margin-bottom: 4vw;
      bottom: 10%;
      width: 100%;
      padding: 2rem;
      left: 50%;
      background-color: rgba($secondary-color, 0.8);
      color: white;
      transform: translateX(-50%);

      @include mq.mq($until: $tablet) {
        width: 100vw;
        padding: 1rem;
        position: relative;
        bottom: 0;
        background-color: rgba($secondary-color, 1);
      }
    }
  }
}

.b-article-intro .b-article-intro__image-text {
  margin-bottom: $space-md;
}
