.b-breadcrumbs {
  padding-top: $space-xs;
  padding-bottom: $space-xs;

  @include mq.mq($until: desktop) {
    padding-top: 0;
    padding-bottom: $space-xs;
  }

  &--border {
    border-bottom: 1px solid $grey-light;
    padding-top: 0;
    padding-bottom: $space-lg;

    @include mq.mq($until: desktop) {
      padding-top: 0;
      padding-bottom: $space-md;
    }
  }

  &__location {
    font-size: $font-xs;
    color: $grey;

    @include mq.mq($until: desktop) {
      display: none;
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    font-size: 14px;

    @include link-init($grey, true, $grey);

    margin-right: 1.7rem;

    &::after {
      position: absolute;
      display: block;
      content: ' / ';
      color: $grey;
      top: 0.25rem;
      right: -0.75rem;
      height: 1.2em;
      width: 1px;
    }

    &:hover {
      @include link-init($primary-color, true, $primary-color);
    }

    &:last-child {
      &::after {
        content: '';
      }
    }

    @include mq.mq($until: desktop) {
      display: none;
      position: relative;
      margin-left: 1.5em;

      &:last-child {
        display: inline-block;

        &::after {
          display: none;
        }

        &::before {
          position: absolute;
          display: inline-block;
          content: '';
          background-image: url($arrow-left);
          background-size: 0.8em;
          background-repeat: no-repeat;
          left: -1.5em;
          top: 0.4em;
          height: 0.8em;
          width: 0.8em;
        }
      }
    }
  }
}
