//Styles included from top level classes.Remove nt- and replace with tailwind classes afterwards.
@layer utilities {
  .nt-border-b-grey {
    border-bottom-style: solid;
    border-bottom-color: rgb(225, 225, 225);
  }
}

@layer base{
  .font-normal { @apply font-[400]}
  .text-xl { @apply text-[20px]}
  .text-2xl { @apply text-[24px]}
  .text-4xl { @apply text-[36px]}
  .text-lg { @apply text-[18px]}
  .my-5 {@apply my-[20px]}
  .mt-16 { @apply mt-[64px]}
  .mt-12 { @apply mt-[48px]}
  .mb-20 { @apply mb-[80px]}
  .mt-10 { @apply mt-[40px]}
  .mb-16 { @apply mb-[64px]}
  .mb-24 { @apply mb-[96px]}
  .py-6 { @apply py-[24px]}
}
