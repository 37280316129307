.page-secondary-branding {
  page: secondary-branding;
}

@page secondary-branding:first {
  padding-bottom: 500px;
}

@page frontpage-no-image:first {
  background: none;
  padding-bottom: 0;
}

@page {
  size: a4;
  margin: 2cm;
  counter-increment: page;
  background-attachment: inherit;
  overflow: visible;
  margin-bottom: 2cm;

  @bottom-center {
    content: "Side "counter(page) "/"counter(pages);
    margin-bottom: 1cm;
  }
}

@media print {
  .toc a::after {
    content: leader('.') target-counter(attr(href), page);
  }
}

.print-page-break {
  page-break-after: always;
  overflow: visible;
}

.print-pdf {
  a[href^="http://"],
  a[href^="https://"],
  a[href^="//"] {
    color: blue !important;
    text-decoration: underline !important;
    box-shadow: none;
  }

  // Typography
  font-size: 13px;

  .b-collapsible__content--small, .b-collapsible__category, li, p, td {
    font-size: 13px;
    line-height: 1.4;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.2em;
  }

  h1, .h1 {
    font-size: 2.6em;
    margin-bottom: 30px;
  }

  h2, .h2 {
    margin-top: 30px;
    font-size: 2.2em;
  }

  .print-section-level-1>h2 {
    margin-bottom: 15px;
  }

  h3, .h3 {
    font-size: 1.6em;
  }

  h4, .h4 {
    font-size: 1.5em;
  }

  h5, .h5 {
    font-size: 1.3em;
  }

  h6, .h6 {
    font-size: 1.1em;
  }

  h4 + h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 1.5em;
    margin-top: 0;
  }

  // Every 'div' that is a diract child of 'body' is a single page
  & > div {
    border-bottom: 10px solid black;
    margin-bottom: 40px;
  }

  // Images fix
  figure img {
    max-width: 100%;
  }

  .editor-align-justify img,
  .justify img {
    width: 100%;
  }

  .atom-main-content { // "Anbefaling" intro text
    padding-left: 20px;
    border-left: 6px solid #ddd;
  }

  div.tabbed {
    margin-left: 20px;
    margin-bottom: 40px;
    padding-top: 20px;
    border-top: 1px solid black;

    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.7em;
    }

    h3 {
      font-size: 1.35em;
    }

    h4 {
      font-size: 1.2em;
      color: #444;
    }

    h5 {
      font-size: 1.1em;
      color: #666;
    }

    h6 {
      font-size: 1em;
      font-style: italic;
      color: #666;
    }
  }

  div.tabbed-1 {
    margin-left: 20px;
  }

  div.tabbed-2 {
    margin-left: 40px;
  }

  div.tabbed-3 {
    margin-left: 60px;
  }

  div.tabbed-4 {
    margin-left: 80px;
  }

  div.tabbed-5 {
    margin-left: 100px;
  }

  // Override spacing classes
  .l-mb-3 {
    margin-bottom: 30px;
  }

  .b-collapsible__content {
    margin-left: 0;
  }

  .b-collapsible {
    padding-left: 0;
  }

  .b-collapsible--subtle {
    margin-left: 0;
  }

  // Tables
  .b-table__wrapper {
    overflow-x: visible;
    background: none;
    clear:both;
    margin-bottom: $space-lg;

    table {
      border: 1px solid $grey-light !important;
    }

    td {
      border: 1px solid $grey-light !important;
    }

    thead td, table th {
      border: 1px solid $grey-light !important;
      box-shadow: none;
    }
  }

  // Override other stuff
  table {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  table td {
    padding: 3px 6px;

    img {
      margin-right: 10px;
      max-width: 150px;
      width: 100%;
    }
  }

  .b-timeline__list {
    display: block;
  }

  .b-timeline__item {
    margin-top: 10px;
    text-align: left;
  }

  .b-timeline__item::before, .b-timeline__item::after {
    display: none;
  }

  .b-timeline__item:last-child {
    text-align: left;
  }
}

// TOC/Front page table
.toc-table {
  border: none !important;
  margin-top: 100px;
}

.toc-right-column {
  border: none !important;
}

.toc-right-text {
  margin-bottom: 2px;
}

.toc-left-column {
  border: none !important;
  vertical-align: top;
  text-align: left;
}

.toc-left-title {
  margin-top: 20px;
}

.cover-table, .cover-table th, .cover-table td {
  border: none !important;
}

.cover-right {
  border: none !important;
}

.cover-left {
  border: none !important;
  vertical-align: top;
  text-align: left;

  h4 {
    font-size: 1.3em;
  }
}

// E-helsestandard
.ehelse {
  &__inline {
    display: inline;
  }

  &__flex-col {
    display: flex;
    flex-direction: column;
  }

  &__cover {
    padding-top: 40px;

    h4 {
      font-size: 18px;
    }

    span {
      font-size: 12px;
    }

    div {
      padding-top: 20px;
    }
  }
}
