.normalize blockquote,
.normalize dl,
.normalize dd,
.normalize h1,
.normalize h2,
.normalize h3,
.normalize h4,
.normalize h5,
.normalize h6,
.normalize hr,
.normalize figure,
.normalize p,
.normalize pre {
  margin: 0;
}

.normalize ol,
.normalize ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.normalize ul li::before {
  content: '';
  display: none;
  width: 0;
  margin: 0;
  font-weight: inherit;
  color: inherit;
}
.normalize {
  h1, h2, h3 {
    @apply font-normal
  }
}
