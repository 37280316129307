.b-chapter-heading {
  display: block;
  position: relative;
  padding: 0 $space-md $space-sm 0;

  @include underline(transparent);

  &__main-heading {
    display: flex;
    width: 100%;

    &::before {
      visibility: hidden;
      display: none;
      content: '';
      height: 1em;
      flex: 0 0 30px;
      margin-top: 0.15em;
      width: 1em;
      background-image: url($arrow-right);
      background-size: .8em;
      background-repeat: no-repeat;
    }
  }

  &--link {
    position: relative;

    @include link-init($black);

    padding: $space-md $space-xs $space-md 0;
    border-top: 1px solid $grey-light !important;
    border-bottom: 1px solid $grey-light !important;
    margin-top: -1px;

    &:hover {
      color: $link-color;
      background: $primary-color-lightest;
    }

    .b-chapter-heading__main-heading::before {
      visibility: visible;
      display: inline-block;
    }

    .h2 {
      padding-right: $space-sm;
      display: block;
    }
  }

  &--line-none {
    border: 0;
    padding-top: 0.5rem;

    .b-chapter-heading__main-heading {
      display: block;
    }
  }

  &--line-bottom {
    border-top: 0;
    border-bottom: 1px solid $link-color !important;
    padding-bottom: 1em;
  }

  &--clean {
    border: 0 !important;
    padding: $space-sm 0;
    display: block;

    .b-chapter-heading__main-heading {
      display: block;

      &::before {
        display: none;
      }
    }
  }
}

.custom-lis .b-chapter-heading {
  border-bottom: 4px solid #212121 !important;
}
