.b-card {
  box-sizing: content-box;
  border-radius: 4px;
  border: 1px solid $grey-light;
  height: 100%;
  max-height: 99.3%;
  display: flex;
  flex-direction: column;

  @include underline(transparent);
  @include focus-visible;

  &:hover {
    * {
      color: $link-color;
    }
  }

  &--grow {
    flex-basis: 50%;
    flex-grow: 1;
    height: auto;
  }

  &--horizontal {
    @include mq.mq($from: tablet) {
      flex-direction: row;
    }

    // The element which is a child of the feature needs to have 100% height.
    .b-card__feature > * {
      display: flex;
      align-items: center;

      // Prevents subpixel issue
      margin-top: -2px;
    }

    .b-card__feature {
      @include mq.mq($from: tablet) {
        display: flex;
        flex-grow: 0;
      }
    }

    .b-card__link {
      flex-grow: 1;
      overflow: auto;

      p {
        margin: 1em 0 0;
      }
    }
  }

  &__feature {
    color: white;

    > img {
      width: 100%;
      display: block;
    }

    flex-shrink: 0;
  }

  &__topic {
    display: inline-block;
    font-size: $meta-text;
    margin-top: 1rem;
    padding: 0.25em;
    background-color: $yellow-light;
  }

  &__link {
    display: block;
    height: 100%;
    position: relative;
    background-color: white;
    padding: 1rem 3rem 1rem 1rem;
    box-shadow: none;

    p {
      margin: 1em 0 0;
    }

    // Removes the margin, if there is only one child element
    > * {
      &:only-child {
        margin-top: 0;
      }
    }

    &--right-arrow {
      &::after {
        @include pseudo-arrow($arrow-right);

        position: absolute;
        right: 1em;
        top: 1.25em;
      }
    }

    &--left-arrow {
      padding: 1rem 2rem 1rem 3rem;

      // Removes the arrow pointing right
      &::after {
        display: none;
      }

      &::before {
        @include pseudo-arrow($arrow-left);

        position: absolute;
        left: 1em;
        top: 1.25em;
      }
    }
  }

  &__heading {
    display: block;
    color: $black;
  }

  &__text {
    font-size: $meta-text;
    color: $grey;
    margin-top: 0.5em;

    // This text should ignore the extra padding, made for the heading and arrow.
    width: calc(100% + 1rem);
  }
}
