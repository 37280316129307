.b-image {
  &--16-9 {
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    img {
      position: absolute;
      width: 100%;
      inset: 0;
    }
  }
}
