.b-alert-bar {
  border-width: 4px;
  border-style: solid;
  background-color: white;
  margin-top: calc((5vw + 5vh) / 2);
  padding: $space-md;

  &--high {
    border-color: $red;
    color: $red;
    padding: 10vh $space-md 8vh $space-md;
    
    .b-link {
      color: $red;
    }
  }

  &--medium {
    border-color: $red;
    color: $red;
    padding: 6vh 1rem 4vh;

    .b-link {
      color: $red;
    }
  }

  &--low {
    border-color: $green-dark;
    color: $green-dark;
    padding: 1rem;

    .b-link {
      color: $green-dark;
    }
  }

  &__date {
    margin-top: 1em;
    font-size: $meta-text;
  }
}
