$maxWidthMap: (
  xs: 380px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
);

.max-w {
  @each $maxWidthName, $maxWidthVal in $maxWidthMap {
    &--#{$maxWidthName} {
      max-width: $maxWidthVal;
    }
  }
}
