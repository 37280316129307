.b-input-search {
  &__inputs {
    height: 3rem;
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border-radius: 4px;
    border: 1px solid $secondary-color;
  }

  &__label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.75em;
    font-size: $font-sm;

    &--dark {
      font-weight: normal;
      color: white;
    }

    &--large {
      font-size: $font-xl;
    }
  }

  &__placeholder {
    position: absolute;
    left: 1.15em;
    top: 0.75em;
    height: 1.6rem;
    overflow-y: hidden;
    color: lighten($grey, 25);
  }

  &__field {
    position: absolute;
    z-index: 2;
    background-color: transparent;
    width: 100%;
    min-width: 0;
    font-size: 1rem;
    flex: 1;
    height: 100%;
    box-sizing: border-box;
    color: $secondary-color;
    padding: 0 1em;
    border: 0;
    text-overflow: ellipsis;

    &::-ms-clear {
      display: none;
    }

    &:focus-visible {
      outline: 3px solid $primary-color;
      outline-offset: 3px;
      border-radius: 2px;
    }

    &--dark {
      box-shadow: none;

      &:focus-visible {
        outline: 3px solid $white;
        outline-offset: 3px;
        border-radius: 2px;
      }
    }
  }

  &__button {
    position: absolute;
    z-index: 3;
    right: 2px;
    top: 2px;
    bottom: 2px;
    font-size: $meta-text;
    padding: 0 1rem;
    background-color: white;
    border: 0;
    border-radius: 0 4px 4px 0;
    cursor: pointer;

    @include focus-visible;

    &:hover {
      background: $yellow-light;
    }

    &::after {
      display: block;
      width: 1.2em;
      height: 1.2em;
      content: ' ';
      float: right;
      background-size: 1.2em;
      background-position: center;
      background-image: sass-svg-uri.encode('<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: Sketch 53.1 (72631) - https://sketchapp.com --><title>search</title><desc>Created with Sketch.</desc><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g id="search" transform="translate(1.000000, 1.000000)" stroke="#025169"><g id="Interface-Essential__x2F__Search__x2F__search" stroke-width="1.0434"><g id="Group_372"><g id="search"><path d="M8.3,16.7 C12.9,16.7 16.6,13 16.6,8.4 C16.6,3.8 13,0 8.3,0 C3.7,0 0,3.7 0,8.3 C0,12.9 3.7,16.7 8.3,16.7 Z" id="Oval_288"></path><path d="M14.2,14.3 L24,24" id="Shape_1872"></path></g></g></g><path d="M8.5,3 C5.5,3 3,5.5 3,8.5" id="Oval_288_2_"></path></g></g></svg>');
      background-repeat: no-repeat;
    }
  }

  &__clear {
    right: 50px;
    color: $primary-color;

    &::after {
      background: sass-svg-uri.encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M37.304 11.282l1.414 1.414-26.022 26.02-1.414-1.413z" fill="#025169" /><path d="M12.696 11.282l26.022 26.02-1.414 1.415-26.022-26.02z" fill="#025169" /></svg>');
      background-size: 24px;
      background-position: center center;
    }
  }
}

.react-autosuggest {
  &__container {
    &--open {
      // No style
    }
  }

  &__input {
    &--open {
      // No style
    }

    &--focused {
      // No style
    }
  }

  &__suggestions-container {
    position: relative;

    &--open {
      // No style
    }
  }

  &__suggestions-list {
    // removes ul default styles
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid $primary-color;
    border-radius: 4px;
    margin-top: 0.25rem;
    max-width: 100%;
    overflow: auto;
    padding: 0;
    list-style-type: none;
    z-index: 10;
  }

  &__suggestion {
    cursor: pointer;
    border-bottom: 1px solid $primary-color;
    padding: 1rem;

    &:last-child {
      border: 0;
      background-color: $primary-color;
      color: white;
      padding: 0.5rem 1rem;
    }

    &--first {
      // No style
    }

    &--highlighted {
      border-color: $primary-color;
      background-color: $grey-lighter;
    }
  }

  &__section-container {
    &--first {
      // No style
    }
  }

  &__section-title {
    // No style
  }
}
