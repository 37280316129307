// TODO: Remove all variables that in the future can be replaced by either designsystem or tailwind
$black: #212121;
$white: white;
$grey: #504f4f;
$grey-light: #d8dada;
$grey-lighter: #f7f7f7;
$blue-dark: #025169;
$blue: #0667c6;
$blue-light: #82b3e2;
$blue-lighter: #e6f3ff;
$blue-lighter-2: #e6f2f6;
$blue-lightest: #f6fbff;
$yellow-dark: #D17A00;
$yellow: #ffc900;
$yellow-light: #fff9c6;
$red-dark: #A0002E;
$red: #d62121;
$red-light: #ffdee6;
$orange-dark: #e45300;
$orange: #f8a83d;
$orange-light: #fff4e6;
$green-dark: #007458;
$green: #95dcca;
$green-light: #ebf5f2;
$purple: #e5bdf5;
$purple-dark: #7c145c;

// Colors only meant for warnings
$green-warning: #007a00;
$yellow-warning: #f0c617;
$red-warning: #ad0017;
$secondary-color: $blue-dark;
$primary-color: $blue;
$primary-color-light: $blue-light;
$primary-color-lighter: $blue-lighter;
$primary-color-lightest: $blue-lightest;
$link-color: $primary-color;

// List of colors
$colors: (
  black: $black,
  white: white,
  grey: $grey,
  grey-light: $grey-light,
  grey-lighter: $grey-lighter,
  blue-dark: $blue-dark,
  blue: $blue,
  blue-light: $blue-light,
  blue-lighter: $blue-lighter,
  blue-lightest: $blue-lightest,
  yellow-dark: $yellow-dark,
  yellow: $yellow,
  yellow-light: $yellow-light,
  red-dark: $red-dark,
  red: $red,
  red-light: $red-light,
  orange: $orange,
  orange-light: $orange-light,
  orange-dark: $orange-dark,
  green-dark: $green-dark,
  green: $green,
  green-light: $green-light,
  purple: $purple,
  purple-dark: $purple-dark,
  green-warning: $green-warning,
  yellow-warning: $yellow-warning,
  red-warning: $red-warning
);

// OLD Font sizes
$extra-large-text: 2.5rem;
$large-text: 1.8rem;
$medium-large-text: 1rem;
$meta-text: 0.8725rem;

// Logo
$logo: '../static/hdir_logo.svg';
$logo-small: '../static/hdir_logo_small.svg';
$logo-qa: '../static/hdir_logo_qa.svg';

// Custom icons
$arrow-down: '../static/icons/arrow_down_hdir.svg';
$arrow-left: '../static/icons/arrow_left_hdir.svg';
$arrow-right: '../static/icons/arrow_right_hdir.svg';
$chevron: '../static/icons/chevron.svg';

@import './variables-common';
