.b-search-tray {
  display: flex;
  justify-content: center;
  flex-flow: column;
  height: 20rem;

  @include mq.mq($until: $tablet) {
    height: 50vh;
  }

  &__item {
    @include mq.mq($from: tablet) {
      animation: fadeInUp 300ms ease;
    }
  }

  &--blue {
    background-color: $blue-dark;
  }
}
