.b-input-text {
  &__label {
    font-size: $meta-text;
  }

  &__input {
    display: block;
    max-width: 25em;
    width: 100%;
    margin-top: 0.5em;
    font-size: $base-font-size;
    padding: 0.5em;
    border-radius: 4px;
    border: 2px solid $secondary-color;
    background: white;

    &:focus {
      outline: 3px solid $link-color;
      outline-offset: 2px;
    }
  }

  &__input--mt-1 {
    margin-top: calc((5vw + 5vh) / 7.5);
  }

  &__error {
    color: $red;
    font-size: $meta-text;
    margin-bottom: 1em;
  }
}
