.b-select {
  position: relative;
  display: flex;
  align-items: center;

  @include mq.mq($until: desktop) {
    display: block;
  }

  &::after {
    transition: all 0.1s ease-out;
    position: absolute;
    display: block;
    bottom: 1.15em;
    right: 1em;
    z-index: -1;
    height: 0.8em;
    width: 0.8em;
    margin-left: 0.33em;
    content: '';
    background-image: url($arrow-down);
    background-size: 0.8em;
    background-repeat: no-repeat;
  }

  &--simple {
    font-size: $meta-text;

    &::after {
      right: 0;
      bottom: 0.6em;
    }
  }

  &--stacked {
    // Width can't be reset, so we use display: table to create a reset behaviour
    display: table;

    &::after {
      top: 2.6em;
    }

    &:hover::after {
      top: 2.8em;
    }
  }

  &--custom-position {
    width: 100%;

    &::after {
      top: 3.4em;
    }

    &:hover::after {
      top: 3.6em;
    }

    .b-select__label {
      font-weight: bold;
      color: $black;
      margin-bottom: 0.75em;
    }

    .b-select__select {
      padding: 0.9em 2.5em 0.9em 1em;
      border-radius: 4px;
    }
  }

  &__label {
    display: inline-block;
    margin-right: 1em;
    color: $secondary-color;
    flex-shrink: 0;
  }

  &__select {
    appearance: none;
    font-size: $meta-text;
    border: 1px solid $secondary-color;
    background-color: transparent;
    padding: 1em 2.5em 1em 1em;
    width: 100%;

    &::-ms-expand {
      display: none;
    }

    &--simple {
      // Width can't be reset, so we use display: table to create a reset behaviour
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $link-color;
      padding: 0;
      padding: 0.5em 1.5em 0.2em 0.2em;
      margin-top: -0.1em;
      text-transform: uppercase;
    }

    &:focus {
      outline: 3px solid $link-color;
      outline-offset: 2px;
    }
  }

  &--positive-z-index {
    z-index: 1;
  }
}
