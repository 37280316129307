details[data-controller="accordion"] {
  --accordion-icon: var(--icon-chevron);
  --accordion-icon-closed: var(--accordion-icon);
  --accordion-icon-open: var(--accordion-icon);
  --accordion-rotation-closed: rotate(90deg);
  --accordion-rotation-open: rotate(-90deg);
}

details[data-controller="accordion"] summary {
  display: inline-flex;
}

details[data-controller="accordion"] div > p{
  margin: 20px 0;
}


details[data-controller="accordion"] summary::-webkit-details-marker,
details[data-controller="accordion"] summary::marker {
  content: "";
  display: none;
}

details[data-controller="accordion"] summary:not(.button-text-variant)::after,
details[data-controller="accordion"] summary.button-text-variant .button-text::after {
  content: var(--accordion-icon-closed);
  display: inline-block;
  transform: var(--accordion-rotation-closed);
  transition: transform 100ms ease-in-out;
  margin-inline: 1rem;
}

details[data-controller="accordion"][open] summary:not(.button-text-variant)::after,
details[data-controller="accordion"][open] summary.button-text-variant .button-text::after {
  content: var(--accordion-icon-open);
  transform: var(--accordion-rotation-open);
}

.accordion-header {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

.newly-updated {
  display: inline-block;
  align-self: start;
  padding: 0 8px;
  border: 1px solid var(--color-gray-lightest);
  border-radius: 10px;
  font-size: 15px;
  font-weight: normal;
  color: var(--color-gray-light);
  text-wrap: nowrap;

  &::before {
    content: var( --icon-ellipse);
    margin-right: 10px;
  }
}
