table {

  &.table-secondary {
    --ehelse-blue-200: #E4F0F4;
    --ehelse-blue-100: #FAFCFD;
    --ehelse-gray-100: #EFEFEF;
    --cell-padding: 11.52px;

    width: auto;
    border-collapse: separate;
    border-spacing: 0;

    & td {
      padding: var(--cell-padding);
      border: 1px solid var(--ehelse-gray-100);
      vertical-align: top;
      font-family: GraphikRegular, sans-serif;
    }

    & tr {
      font-family: GraphikMedium, sans-serif;

      &:first-child td {
        padding: 16px 11.52px;
        background-color: inherit;
        font-family: GraphikRegular, sans-serif;
        font-size: 17px;
        font-weight: var(--font-weight-regular);
        line-height: 30px;
        text-align: left;
        text-transform: inherit;
      }

      &:nth-child(even){
        background-color: var(--ehelse-blue-100);
      }

      &:last-child {

        & td {
          border-bottom: 1px solid var(--ehelse-gray-100); // To overwrite styling from shame.scss
        }

        & td:first-child {
          border-bottom-left-radius: var(--border-radius);
        }

        & td:last-child {
          border-bottom-right-radius: var(--border-radius);
        }
      }
    }

    & th {
      padding: var(--cell-padding);
      background: var(--ehelse-blue-200);
      vertical-align: top;
      border-color: var(--ehelse-gray-100);

      &:first-child {
        border-top-left-radius: var(--border-radius);
      }

      &:last-child {
        border-top-right-radius: var(--border-radius);
      }
    }

  }
}
