// Removes spacing aroung the heading, sets default weight to bold
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h1-mobile,
.h2-mobile,
.h3-mobile,
.h4-mobile,
.h5-mobile {
  margin: 0;
  padding: 0;
  font-weight: bold;
  line-height: 1.2;
}

// Heading sizes
h1,
.h1 {
  @include mq.mq($until: $tablet) {
    font-size: $font-h1-mobile;
  }

  font-size: $font-h1;
}

h2,
.h2 {
  @include mq.mq($until: $tablet) {
    font-size: $font-h2-mobile;
  }

  font-size: $font-h2;
  font-weight: bold;
}

h3,
.h3 {
  @include mq.mq($until: $tablet) {
    font-size: $font-h3-mobile;
  }

  font-size: $font-h3;
}

h4,
.h4 {
  @include mq.mq($until: $tablet) {
    font-size: $font-h4-mobile;
  }

  font-size: $font-h4;
  font-weight: bold;
}

h5,
.h5 {
  @include mq.mq($until: $tablet) {
    font-size: $font-h5-mobile;
  }

  font-size: $font-h5;
  font-weight: bold;

  &--thin {
    color: $grey;
  }
}

// Heading weights
.h0, // 'h0' is only used to reset styles on dynamic headings we don't know the type of
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  &--light {
    font-weight: lighter;
  }

  &--normal {
    font-weight: normal;
  }

  &--bold {
    font-weight: bold;
  }

  &--brand-color {
    color: $secondary-color;
  }

  &--secondary-color {
    color: $primary-color;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

.h1-mobile {
  font-size: $font-h1-mobile;
}

.h2-mobile {
  font-size: $font-h2-mobile;
}

.h3-mobile {
  font-size: $font-h3-mobile;
}

.h4-mobile {
  font-size: $font-h4-mobile;
}

.h5-mobile {
  font-size: $font-h5-mobile;
}
