@layer base {

/* Copy-paste */

.prose > * {
  max-width: 76ch; // Unsure why we use ch. It seems unnecessary complicated
}

.prose-negative {
  @apply text-white;
}

.prose p {
  @apply mt-[0.75em] sm:mt-[1.5em] first:mt-0 mb-0;
}

.prose a {
  box-shadow: none;
  color: var(--color-blue) !important;
  @apply underline hover:no-underline;

  &:hover {
    color: var(--color-red) !important;

  }
}

.prose-negative .prose a,
.prose .prose-negative a,
.prose.prose-negative a {
  color: var(--color-white) !important;

}

.prose ul {
  @apply ml-5 my-[0.5em] sm:my-[0.75em];
}

.prose ul li::before {
  @apply inline-block text-blue font-bold w-5 -ml-5;

  content: '\2022' / "";
}

.prose-negative .prose ul li::before,
.prose .prose-negative ul li::before,
.prose.prose-negative ul li::before {
  @apply inline-block text-white;
}

.prose table {
  @apply w-full;
}

.prose :is(h2,h3,h4,h5) ~ p {
  margin-top: 0 !important;
}

.prose > :first-child {
  @apply mt-0;
}
/* End copy-paste */

  .space-y-8 { @apply space-y-[32px] }
  .ml-5 { @apply ml-[20px] }

}
