@layer base {
  .bg-lightGray { background: var(--color-gray-lightest); }
  .bg-box { background: var(--color-bg-blue); }
  .bg-boxPink { background: var(--color-bg-purple); }

  .border-purple{ border-color: var(--color-border-purple); }
  .border-gray{ border-color: var(--color-gray); }
  .border-white{ border-color: var(--color-white); }
}

.zoho {
  svg{
    display: block;
    max-width: 100%;
  }
}
