/* copy-paste */
.roadmap-goal-timeline details[data-controller="accordion"].read-more {
  --accordion-icon-closed: var(--icon-plus);
  --accordion-icon-open: var(--icon-minus);
  --accordion-rotation-closed: unset;
  --accordion-rotation-open: unset;
}

.roadmap-goal-timeline details[data-controller="accordion"]:not([open]).read-more summary .read-more-text,
.roadmap-goal-timeline details[data-controller="accordion"][open].read-more summary .read-less-text {
  display: none;
}

.is-previous-year {
  width: 164px;
  background-color: rgb(239 239 239 / 60%);
}

.multiGoalSection {
  margin-top: 0;
}

.tag-list-wrapper {

  & div {
    width: fit-content;
  }
}

@media screen and (width >= 770px) {

  .strike-through {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      z-index: -10;
      border-top: 2px solid #efefef;
    }

    & * {
      z-index: 10;
    }

  }
}
/* copy-paste end*/

// TODO: Consolidate tailwind expansions in a separate file if this is not fixed by FRISK
@layer base {
  .p-0 { @apply p-[0px] }

  .py-1 { @apply py-[4px] }
  .py-2 { @apply py-[8px] }
  .py-3 { @apply py-[12px] }
  .py-8 { @apply py-[32px] }
  .im-py-8 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .px-2 { @apply px-[8px] }
  .px-3\.5 {
    padding-left: 14px;
    padding-right: 14px;
  }
  .px-6 { @apply px-[24px] }

  .pl-1 { @apply pl-[4px] }
  .pl-2\.5 { @apply pl-[10px] }
  .pl-3 { @apply pl-[12px] }

  .pr-1 { @apply pr-[4px] }

  .pt-2 { @apply pt-[8px] }
  .pt-4 { @apply pt-[16px] }
  .pt-6 { @apply pt-[24px] }

  .pb-2 { @apply pb-[8px] }
  .pb-4 { @apply pb-[16px] }
  .pb-10 { @apply pb-[40px] }
  .pb-12 { @apply pb-[48px] }

  .my-6 { @apply my-[24px] }

  .mt-1 { @apply mt-[4px] }
  .mt-2 { @apply mt-[8px] }
  .mt-10 { @apply mt-[40px] }
  .mt-16 { @apply mt-[64px] }

  .ml-20 { @apply ml-[80px] }

  .space-x-4 { @apply space-x-[16px] }

  .gap-y-4 { @apply gap-y-[16px] }

  .rounded-2xl { @apply rounded-[16px] }
  .rounded-l-2xl { @apply rounded-l-[16px] }
  .rounded-r-2xl { @apply rounded-r-[16px] }



  .nt-divide-lightGray { @apply divide-[#efefef] }

  .border-0 { border-width: 0px }
  .border-2 { @apply border-[2px] }
  .border-b-8 {
    @apply border-t-[0px];
    @apply border-l-[0px];
    @apply border-r-[0px];
    @apply border-b-[8px];
  }
  .border-l-8 {
    @apply border-t-[0px];
    @apply border-l-[8px];
    @apply border-r-[0px];
    @apply border-b-[0px];
  }

  .border-invisible { border-color: transparent }

  .border-lightGray { border-color: var(--color-gray-lightest) }
  .border-greyC { border-color: var(--color-grayC) }
  .border-bodyTextB { border-color: var(--color-gray); }
  .border-blueD { border-color: var(--color-blueD); }
  .border-oceanC { border-color: var(--color-oceanC); }
  .border-rubyC { border-color: var(--color-rubyC); }
  .border-aubergine { border-color: var(--color-aubergine); }

  .bg-white { background: var(--color-white); }
  .bg-aubergine { background: var(--color-aubergine); }
  .bg-blue { background: var(--color-blue); }
  .bg-blueC { background: var(--color-blueC); }
  .bg-blueF { background: var(--color-blue-light); }
  .bg-oceanC { background: var(--color-oceanC); }
  .bg-oceanD { background: $green-light; }
  .bg-rubyC { background: var(--color-rubyC); }
  .bg-rubyD { background: var(--color-bg-ruby); }
  .bg-menuB { background: var(--color-bg-menu); }
  .bg-grayC { background: var(--color-gray-light); }
  .bg-grayD { background: $grey-lighter; }
  .im-bg-invisible { background: transparent !important; }


  .text-white { color: var(--color-white); }
  .text-bodyText { color: var(--color-gray-dark); }
  .text-bodyTextB { color: var(--color-gray); }
  .text-grayC { color: var(--color-gray-light); }
  .text-blueC { color: var(--color-blueC); }
  .text-blue { color: var(--color-blue); }
  .text-oceanC { color: var(--color-oceanC); }
  .text-rubyC { color: var(--color-rubyC); }
  .text-aubergine { color: var(--color-aubergine); }

  .fill-current { fill: currentColor }
}

@layer utilities {
  .gap-2 { @apply gap-[8px] }

  .gap-x-3 { @apply gap-x-[12px] }

  .gap-y-1\.5 { @apply gap-y-[6px] }

  .font-medium { font-weight: 500; }

  .text-1xl { font-size: 22px; }

  .outline-blue { outline-color: var(--color-blue) !important;}

  .divide-y-2 { @apply divide-y-[2px] }

  .divide-x-2 { @apply divide-x-[2px] }

  .border-y-solid>:not([hidden])~:not([hidden]) {
    border-bottom-style: solid;
    border-top-style: solid;
  }
  .border-x-solid>:not([hidden])~:not([hidden]) {
    border-left-style: solid;
    border-right-style: solid;
  }
  .from-white {
    --tw-gradient-from: #fff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .from-box {
    --tw-gradient-from: #e4f0f4 var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(228, 240, 244, 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
}

.zoho-richtext {
  p {
    margin: 20px 0;
    max-width: 64ch;
  }

  ul {
    margin: 20px 0;
    max-width: 64ch;
    padding-left: 20px;
    list-style-type: disc;

    li {
      font-family: GraphikRegular, sans-serif;
      font-size: 1.125rem;
      line-height: 1.76;
      padding-left: .5rem;
    }
  }

  a {
    color: var(--color-blue);
    text-decoration: underline;
    transition: color .25s;

    &:hover {
      color: var(--color-red);
      text-decoration: underline;
    }
  }
}


// This does not spark joy 😞
.divide-lightGray>:not([hidden])~:not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 239, 239, var(--tw-divide-opacity));
}

.border-bottom-lightGray {
  border-bottom-color: var(--color-gray-lightest);
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
.border-bottom-Gray--fat {
  border-bottom-color: var(--color-gray);
  border-bottom-width: 8px;
}
.border-left-Gray--half-opacity {
  border-left: 2px solid var(--color-gray-50);
}
.border-left-lightGray {
  border-left-color: var(--color-gray-lightest);
  border-left-style: solid;
  border-left-width: 2px;
}

.timeline-flex-col-xs-row {
  flex-direction: column;
  @media (width > 480px) { flex-direction: row; }
}
.timeline-flex-col-md-row {
  flex-direction: column;
  @media (width > 768px) { flex-direction: row; }
}
.timeline-flex-col-lg-row {
  flex-direction: column;
  @media (width > 1024px) { flex-direction: row; }
}

.timeline-accordion-button {
  font-weight: 500;
  overflow-wrap: break-word;
  &:hover {
    text-decoration: underline;
    }
}
