/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'),
    url('../static/fonts/WorkSans-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../static/fonts/WorkSans-Regular.woff')
      format('woff');
}

/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: bold;
  src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
    url('../static/fonts/WorkSans-SemiBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../static/fonts/WorkSans-SemiBold.woff')
      format('woff');
}

/* work-sans-italic - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Work Sans Italic'), local('WorkSans-Italic'),
    /* Super Modern Browsers */ url('../static/fonts/WorkSans-Italic.woff')
      format('woff'),
    /* Safari, Android, iOS */ url('../static/fonts/WorkSans-Italic.ttf')
      format('truetype');
}

$base-font-size: $font-body;

html {
  font-size: $base-font-size;
}

body {
  color: $text-color;
  font-weight: normal;
  line-height: $base-line-height;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

a {
  &:link,
  &:hover,
  &:visited,
  &:active {
    color: $black;
  }

  &:hover {
    color: $link-color;
  }

  text-decoration: none;

  // due to some change in chrome 108, box shadow on text
  // spanning mulitple lines broke. This is fixed by
  // using inset shadow instead.
  box-shadow: inset 0 -1px 0 $link-color;
}

.t {
  @each $spaceName, $spaceValue in $flexboxgrid-breakpoints {
    // Responsive text alignment
    &-#{$spaceName}-right {
      text-align: left;

      @include mq.mq($from: $spaceValue) {
        text-align: right;
      }
    }
    &-#{$spaceName}-left {
      text-align: left;

      @include mq.mq($from: $spaceValue) {
        text-align: left;
      }
    }
    &-#{$spaceName}-center {
      text-align: left;

      @include mq.mq($from: $spaceValue) {
        text-align: center;
      }
    }

    // Brute force text alignment
    &-right {
      text-align: right;
    }

    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }
  }

  // Text color helper class
  @each $colorName, $colorVal in $colors {
    &--#{$colorName} {
      color: $colorVal;
    }
  }

  &--small {
    font-size: $meta-text;
  }

  &--tab-left {
    display: inline-block;
    margin-left: 1.5rem;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--infotext {
    display: inline-block;
    margin-left: 1em;
    font-size: $meta-text;
  }

  &--bold {
    font-weight: bold;
  }
}

small {
  font-size: $meta-text;
}

// The CSS meant for the content from the rich text in Enonic
.t-body-text,
.b-ninja {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1.5em;

    &:focus {
      outline: 0;
      color: $primary-color;
    }
  }

  // Removes heading styles for blocks
  [class^='b-'] {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }
  }

  // Use max width on any paragraphs. Even less so inside any collapsibles.
  p {
    margin: 1em 0;
    max-width: 860px;
  }

  .b-collapsible {
    max-width: 840px;
  }

  // Removes the top margin from the first heading
  > :first-child {
    margin-top: 0;
  }
}

.t-xxl-mobile {
  font-size: $font-xxl-mobile;
}

.t-xl-mobile {
  font-size: $font-xl-mobile;
}

.t-l-mobile {
  font-size: $font-l-mobile;
}

.t-md-mobile {
  font-size: $font-md-mobile;
}

.t-sm-mobile {
  font-size: $font-sm-mobile;
}

.t-xs-mobile {
  font-size: $font-xs-mobile;
}

.t-xxl {
  font-size: $font-xxl;
}

.t-xl {
  font-size: $font-xl;
}

.t-l {
  font-size: $font-l;
}

.t-md {
  font-size: $font-md;
}

.t-sm {
  font-size: $font-sm;
}

.t-xs {
  font-size: $font-xs;
}

/** only used by editors */
.t-xxs {
  font-size: 0.75rem;
}

.t-ninja {
  h1, h2, h3, h4, h5 {
    margin-top: $space-lg;
  }

  .b-collapsible__heading {
    margin: 0;
  }
}
