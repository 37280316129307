ul.regulation-labels {
  list-style: none;
  font-size: 15px;
  font-weight: normal;
  color: #727278;
  margin-block: 10px;
  padding-left: 0px;

  li {
    display: inline-block;

    /* Remove bullet (TODO Fix issue globally where <li> is polluted) */

    &::before {
      content: unset;
    }
  }

  li + li::before {
    content: " | ";
    display: inline-block;
    margin-left: 0;
    color: #f1f1f1;
    padding-inline: 8px;
  }
}
