.b-report-frontpage {
  $listBorder: 1px solid $grey-light;

  &__box {
    position: relative;
    padding: 1.5rem;
    z-index: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: clamp(20rem, 90vw, 60rem);

    &--blue-dark {
      background-color: $blue-dark;
      color: $white;
    }

    &--blue-light {
      background-color: $blue-lighter;
      color: $black;
    }


    &.tl {
      border-top-left-radius: 2rem;
    }

    &.tr {
      border-top-right-radius: 2rem;
    }

    &.bl {
      border-bottom-left-radius: 2rem;
    }

    &.br {
      border-bottom-right-radius: 2rem;
    }
  }

  &__detail {
    font-size: $font-xs;
    margin-right: 1rem;
    opacity: 0.8;
  }

  &__image {
    margin-bottom: -2rem;
    margin-top: -4rem;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: 50% 50%;
    max-height: 25rem;
  }

  &__list {
    margin-top: 0.5rem;
    padding-top: 1rem;
    max-width: 40rem;

    &__title {
      border-bottom: $listBorder;
      max-width: 40rem;
      padding-bottom: 0.25rem;
    }

    &:last-child {
      margin-bottom: 0.5rem;
    }

    &-item {
      margin-bottom: 1rem;
    }

    &-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: none;
      border-bottom: $listBorder;
      padding-bottom: 0.5rem;

      &> span {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--parent {
        font-weight: 600;

      }

      &--child {
        font-size: $font-body;
      }

      &-kicker {
        font-size: $font-xs;
        margin-bottom: 0.25rem;
      }

      &-prefix {
        span {
          min-width: 2rem;
        }
      }

      &-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__sublist {
    margin-top: 0.5rem;
    padding-top: 0.5rem;

    & > * + * {
      margin-top: 0.5rem;
    }

    & > * {
      margin-bottom: 0;
    }
  }
}
