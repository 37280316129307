.b-filter-list {
  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    display: inline-block;
    margin-right: 1em;
    font-size: $font-xs;
  }

  &__list-item-link {
    margin: 0.5em;
    color: $link-color !important;

    &--active {
      background-color: $yellow-light;
      color: $black !important;
      margin: 0;
      padding: 0.5em;

      @include link-init($black);
    }
  }
}
