// Set override class on top element to override existing styling from hdir

.override {
  & a {
    box-shadow: none;
  }
  & ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  & [role=button],button {
    cursor: pointer;
}
}
