.b-collapsible {
  $space: 1.75rem;
  $spaceSmall: 0.75rem;
  padding: $space-sm $space-md;
  border: 1px solid transparent;
  box-sizing: border-box;

  &__button {
    color: $black;
    font-weight: 400;

    .b-collapsible__code {
      float: right;
    }

    &--color-bold.b-collapsible__button--active {
      color: $link-color;
    }
  }

  .b-collapsible {
    background: none;
    border: none;
    padding: $space-sm $space $space-sm 0;
    margin-left: 0;

    &--plain {
      border: 1px solid transparent;
      padding: 1rem 1.5rem;
    }

    .b-collapsible__content {
      padding-left: $space;

      // break elements such as long links
      word-break: break-word;

      // breaking words inside tables looks bad, see HDF-1886
      table {
        word-break: normal;
      }

      &--padding-none {
        padding-left: 0;
      }
    }

    &--tiny {
      padding: $spaceSmall 0 $spaceSmall/2;
    }

    &__content {
      padding-bottom: 0;
    }

    .b-collapsible--small:nth-child(odd) {
      background: $grey-lighter;
    }
  }

  &--medium {
    border-width: 1px;

    .b-collapsible__content {
      padding-top: $space-sm;
      padding-left: 0 !important;
    }
  }

  &--small {
    border-width: 1px;
    border: 0;
    margin-top: 0;

    .b-collapsible--small {
      padding-left: $space-xs !important;
    }

    .b-collapsible--small .b-collapsible--small {
      padding-left: 0 !important;
    }
  }

  &--tiny {
    margin-top: 0;
    padding: 0;
    display: block;

    .b-collapsible__content {
      padding-left: 0 !important;
    }
  }

  &--clean {
    margin-top: 0;
    padding: 0;
    display: block;
  }

  &--no-border {
    border: 0;
  }

  &--padding-none {
    padding: 0;
  }

  &--border-bottom {
    border-bottom: 1px solid $grey-light !important;
    padding-bottom: 1rem;
  }

  &__tag {
    display: none;
  }

  &__tag-content {
    display: none;
  }

  &--columns.b-collapsible--background.b-collapsible--active {
    padding: 0;
    position: relative;

    .b-collapsible {
      @include mq.mq($until: wide) {
        margin-left: 0;
      }

      margin-left: $space;
    }

    > .b-collapsible__button {
      margin-top: $space-sm;
      margin-bottom: $space-sm;
      margin-left: $space-xl;
      padding-right: $space-sm;
      width: 80%;

      @include mq.mq($from: wide) {
        width: 62%;
      }
    }

    > .b-collapsible__content {
      padding-left: $space-xl;

      @include mq.mq($from: wide) {
        width: 62%;
      }
    }

    > .b-collapsible__meta-content {
      width: 30%;
      font-size: $font-xs;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-left: 1px solid $grey-light;
      box-sizing: border-box;

      .b-icon-with-text {
        text-transform: uppercase;
        margin-bottom: $space-sm;
      }
    }

    > .b-collapsible__meta-content-mobile {
      @include mq.mq($until: wide) {
        display: block;
      }
    }

    > .b-collapsible__footer {
      @include mq.mq($until: wide) {
        width: 80%;
      }

      padding-left: $space-lg;
      padding-bottom: $space;
      width: 100%;
      justify-content: flex-start;

      button {
        margin-right: $space;
      }

      .b-button--primary {
        display: none;
      }
    }
  }

  &--background-lis {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

    &.b-collapsible--active {
      background: white;
      border: 1px solid $grey-light !important;
      margin-bottom: $space-sm;

      .b-collapsible__button {
        display: block;
        margin-right: $spaceSmall;
      }
    }
  }

  &--active.b-collapsible--background {
    background: white;

    // using important to simplify the lis-page. :(
    border: 1px solid $grey-light !important;
    margin-left: -$space-lg;

    @include mq.mq($until: wide) {
      margin-right: -$space-lg;
    }

    margin-bottom: $space;
    padding-left: $space-xl * 0.88;
    padding-right: $space;

    .b-collapsible__button {
      display: block;
      margin-right: $spaceSmall;
    }
  }

  &__meta-content {
    background: $grey-lighter;
    display: none;
    padding: $space;
  }

  &--active {
    .b-collapsible__tag {
      font-size: $font-xs;
      font-weight: normal;
      display: inline-block;
      transition: background 0.1s ease-out;
      background: $yellow-light;
      padding: $spaceSmall / 1.5 $spaceSmall * 1.75;
      margin: $spaceSmall / 2 $spaceSmall $spaceSmall 0;
      border: 0;
      border-radius: 20px;
      position: relative;
      cursor: help;

      // If first element is a div without any spacing class, add some top spacing
      + div:not([class]) {
        margin-top: $spaceSmall;
      }

      // If first element is a heading use less top spacing
      + h2,
      + h3,
      + h4 {
        margin-top: $spaceSmall;
      }

      span {
        border-bottom: 1px dashed $black;
        padding-bottom: 0;
      }

      &:hover {
        background: $yellow;
      }

      &:focus,
      &:active {
        outline: none;
      }

      &.show {
        background: $white;

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          bottom: -15px;
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid $white;
          z-index: 9;
        }
      }

      &--no-action {
        span {
          border: none;
        }

        &:hover {
          background: $yellow-light;
          cursor: default;
        }

        &.show {
          background: $yellow-light;
        }
      }
    }

    .b-collapsible__tag-content.show {
      display: block;
      padding: $spaceSmall;
      margin-top: -$spaceSmall;
      margin-bottom: $space;
      background: $yellow-light;
      font-size: $font-xs;
      max-width: 800px;
    }

    .b-collapsible__meta-content {
      @include mq.mq($from: wide) {
        display: block;
      }
    }
  }

  &__meta-content-mobile {
    font-size: $font-xs;
    width: 90%;
    padding-left: $space-lg;

    .b-icon-with-text {
      text-transform: uppercase;
      padding: 0;
      border: 0;
    }

    display: none;
  }

  &__code {
    font-weight: normal;
    font-size: $meta-text;
    color: $grey;
    margin-top: 0;
    white-space: nowrap;
  }

  &__category {
    margin-right: 7%;
    font-size: $meta-text;
    margin-bottom: 0.5em;
  }

  &__button {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: none;
    border: 0;
    text-align: left;
    padding: 0 2rem 0 0;
    overflow: visible;
    font-size: 1em;
    cursor: pointer;

    &:hover {
      color: $link-color;
    }

    &:focus {
      color: $primary-color;

      h5 {
        font-weight: bold;
      }
    }

    &::before {
      // image used has h: 17px w: 29px
      // calculating the ratio to make it easier to control the size
      --HWRatio: calc((17 / 29));

      display: block;
      position: absolute;
      content: ' ';
      width: 1.25rem;
      height: calc(1.25rem * var(--HWRatio));
      transform: rotate(180deg);
      background-image: url("../static/icons/chevron.svg");
      background-repeat: no-repeat;
      background-size: contain;
      right: 0;
      top: 4px;
    }

    &--arrow-left {
      padding-left: $space;

      &::before {
        right: auto;
        left: 0;
      }
    }

    &:active {
      color: $black;
    }

    /* Dont add before-arrows when simple button inside Collapsible */
    &.b-button {
      &::before {
        display: none;
      }

      &:hover,
      :focus {
        background: $primary-color;
        color: white !important;
      }
    }

    &--small {
      h4 {
        font-size: $font-h5;
      }
    }

    &--subtle {
      font-size: $meta-text;
      box-shadow: none !important;
      padding-left: 0;

      > * {
        display: inline !important;
        border-bottom: 1px dashed $black;
      }

      &::before {
        display: none;
      }
    }

    &--medium {
      &::before {
        top: 5px;
        width: 0.66em;
        height: calc(0.66em * var(--HWRatio));
      }
    }

    &--tiny {
      display: block;
      max-width: 400px;
      padding-right: 20px;
      position: relative;
      border-bottom: 2px dashed $primary-color;

      h3,
      h4,
      h5 {
        padding-bottom: 4px;
        display: block;
        text-transform: none;
        font-size: $meta-text;
        font-weight: normal;
      }

      &::before {
        display: none;
      }

      &::after {
        display: block;
        position: absolute;
        content: '+';
        font-size: 20px;
        color: $primary-color;
        width: 16px;
        height: 16px;
        margin-left: -$space;
        transform: rotate(0deg);
        right: 0;
        top: -2px;
      }

      &.b-collapsible__button--active::after {
        transform: rotate(45deg);
        font-size: 26px;
        right: -3px;
      }
    }

    &--active {
      &::before {
        top: 0;
        transform: rotate(0deg);
      }
    }
  }

  &__heading {
    margin: 0;
    text-transform: none;
  }

  h5 &__heading {
    font-weight: normal;
  }

  &__content {
    animation: fadeInUp 300ms ease;
    padding-top: $spaceSmall;
    padding-bottom: $space;

    p {
      margin-bottom: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    // Specific for "kontradiskjon" collapsibles
    .b-collapsible__content--contra p {
      margin-top: 0;
      margin-bottom: $space;
    }

    h4 + div,
    h5 + div {
      margin-top: $spaceSmall;
    }

    h2,
    h3,
    h4,
    h5 {
      margin-top: $space;
    }

    h3:first-child,
    h4:first-child,
    h2:first-child {
      margin-top: 0;
    }

    button h4 {
      margin: 0;
    }

    .b-collapsible__button--active {
      margin-bottom: $spaceSmall;
    }

    .b-icon-with-text--clean .b-icon-with-text__text {
      // Fix for antibiotika
      font-size: $meta-text;
      font-weight: bold;
    }

    &--small {
      font-size: $meta-text;
      margin-left: 0;
    }
  }

  &__subheading {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: $meta-text;
  }

  &__meta-heading {
    font-size: $meta-text;
    margin-top: 10px;
  }

  &__footer {
    margin-top: $space;
    margin-bottom: $spaceSmall;
    display: none;
    justify-content: space-between;
    align-items: center;

    .b-collapsible__button {
      display: inline-block;
      width: auto;
    }
  }

  &--active .b-collapsible__footer {
    display: flex;
  }

  &__meta-date {
    font-size: $font-xs;
    margin-left: $spaceSmall;

    &--from-to {
      display: flex;
      margin-top: $space-xs;
      margin-left: 0;
      color: $grey;
    }

    &-from {
      margin-right: $space;
    }
  }

  &__link {
    display: inline-block;
    float: right;

    @include link-init($black, true, $link-color);

    &::after {
      @include pseudo-arrow($arrow-right);
    }
  }
}

.custom-lis .b-collapsible {
  font-weight: 400;
  border-top: 1px solid $grey-light;

  &:last-child {
    border-bottom: 1px solid $grey-light;
  }

  &:first-child {
    border-top: transparent;
  }

  &--no-border {
    border-top-color: transparent;
  }
}
