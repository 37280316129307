.b-long-short-heading {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  &__sub-wrapper {
    width: 100%;

    @include mq.mq($from: tablet) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__sub {
    font-size: $meta-text;
    display: flex;
    align-items: center;
    margin-top: $space-sm;
  }

  &__link {
    margin-top: $space-sm;
    display: flex;
    align-items: center;
  }

  &__sub-icon {
    height: 1.5em;
    width: 1.5em;
    margin-right: $space-xs;
  }

  &__text {
    display: block;
    font-size: $meta-text;
  }
}

.b-approved {
  margin-top: 0.7rem;
  font-size: 13px;
}
