.alert-box {
  position: relative;
  z-index: 1;
  background-color: var(--color-blue-light);

  .alert-inner {
    display: flex;
    column-gap: var(--spacing-10);

    &::before {
      content: var(--icon-info);
    }

    .alert-text {

      a,
      a:visited,
      a:hover {
        color: var(--color-gray-dark);
      }

      p {
        max-width: none;
      }
    }
  }
}
