@keyframes fadein {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
}

.b-content-filter {
  margin-top: 1rem;

  &__buttons {
    margin-bottom: 1rem;

    @media (width >= 40rem) {
      display: flex;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-start;
    margin-bottom: 1rem;

    @media (width >= 40rem) {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  &__filter-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $space-xs;
  }

  &__filter-tag {
    padding: 0.25rem $space-xs;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__reset {
    display: flex;
    justify-content: flex-end;
  }

  &__reset-button {
    padding: $space-xs;
  }

  &__open {
    padding: 1rem;
    border-radius: 7px;
    border: 1px solid $blue-dark;
  }

  &__filter-group {
    animation: fadein 0.2s;

    @media(width >= 40rem) {
      column-count: 2;
      column-gap: 5%;
      margin-bottom: 1rem;
    }
  }

  &__checkbox-group {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__show-results {
    display: flex;
    justify-content: center;
  }

  &__results-number {
    margin-top: $space-xs;
    padding-bottom: $space-xs;
  }
}
