// Unfortunally Flexboxgrid adds text-align rules. This snippet makes it left align again.
@each $breakpoint in $flexboxgrid-breakpoints {
  $name: nth($breakpoint, 1);

  .center-#{$name} {
    text-align: left;
  }

  .end-#{$name} {
    text-align: left;
  }
}

.center-xs,
.end-xs {
  text-align: left;
}

// Used to the classnames on the blocks
.block-hint {
  [class^='b-']:not([class*='__']) {
    position: relative;

    &::before {
      content: attr(class);
      position: absolute;
      font-size: 0.7rem;
      top: -0.25em;
      color: #919;
      font-weight: bold;
      background: white;
      border: 1px solid grey;
      z-index: 2;
      padding: 0.25em;
    }
  }
}

.grid-example {
  > div {
    border: 2px solid $black;
    margin-bottom: 0.5rem;
  }

  div > div {
    background-color: $yellow;
    border: 2px solid $blue-light;
    border-radius: 4px;
  }
}

.tilskudd-button {
  box-shadow: none;
  color: white !important;
}

.tilskudd-button:hover {
  color: #0667c6 !important;
}

.tilskudd-button__secondary {
  box-shadow: none;
  color: #0667c6 !important;
}

.tilskudd-button__secondary:hover {
  color: white !important;
}

.b-select__option {
  font-size: 16px;
}

.hidden-display {
  display: none;
}

.fra_end_note em {
  font-family: work-sans, sans-serif;
  src: url('../static/fonts/WorkSans-Italic.ttf') format(ttf);
  font-style: italic;
}

.normative_ref::before {
  margin-left: -1.75rem
}

.normative_ref-box {
  margin-left: -1.75rem
}

.normative_ref--wrapper {
  padding: 0 1.5rem !important;
  border-top: none !important;
}

.normative_ref--text {
  margin-left: 0.6em;
}

/* consider moving everyting below to separate .scss file for map-wizard */
.figure-wrapper {
  background-color: #f7f6f5;
}

.map-wizard {
  min-height: 800px;
}

.c-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 80px;
  box-sizing: content-box;
  width: 100%;
}

.c-loading .c-loading__indicator {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.c-loading .c-loading__indicator div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(.5,0,.5,1) infinite;
  border-color: #fff transparent transparent transparent;
}

.c-loading .c-loading__indicator div:nth-child(1) {
  animation-delay: -.45s;
}

.c-loading .c-loading__indicator div:nth-child(2) {
  animation-delay: -.3s;
}

.c-loading .c-loading__indicator div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 500px) {
  .map-wizard{
    min-height: 500px;
  }
}

