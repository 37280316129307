.roadmap-overview {

  .roadmap-overview-container {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-80);

    @media (width <= 768px) {
      flex-direction: column;
    }

    .roadmap-overview-aside {
      flex: 1;

      ul {
        list-style: none;
        padding: 0px;
        margin: 20px 0;
      }

      .sticky {
        position: sticky;
        top: 0;
      }
    }

    .roadmap-overview-main {
      display: flex;
      flex: 2;
      flex-direction: column;
      gap: var(--spacing-80);

      .roadmap-overview-section {
        h3 {
          font-weight: 400;
        }
        h4 {
          font-weight: 500;
        }
        p {
          margin: 20px 0;
          max-width: 64ch;
          overflow-wrap: break-word;
        }
      }
    }

  }

  .strategy-section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20);
    margin-top: var(--spacing-60);

    & img {
      align-self: flex-start;
    }
  }
}
