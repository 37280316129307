@media print {
  body {
    background-color: white;
    color: black;
    font-size: 16px;
    -webkit-print-color-adjust: exact;
  }

  a[href^="http://"],
  a[href^="https://"],
  a[href^="//"] {
    color: blue !important;
    text-decoration: underline !important;
  }

  .b-main-header {
    display: none;
  }

  .b-breadcrumbs {
    display: none;
  }

  .b-footer {
    display: none;
  }

  aside {
    display: none;
  }

  article {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .b-article-intro__image-wrapper {
    display: none;
  }

  .b-article-intro__text {
    width: 100%;
  }

  // We assume the search tray and the box for the bottom is on the same level
  .b-search-tray ~ .b-box {
    display: none;
  }

  // Hide everything else but the 'main' and highchart elements element
  *:not(.highcharts-container) {
    visibility: hidden;
  }

  .highcharts-container,
  .highcharts-container *,
  main,
  main * {
    visibility: visible;
  }

  .print-page-break {
    page-break-after: always;
  }
}
