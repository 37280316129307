.b-link {
  color: $primary-color;

  &__icon {
    display: inline-block;
    content: ' ';
    height: 1.6em;
    width: 1.2em;
    margin-right: 0.6em;
    margin-bottom: -0.6em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 center;

    &.chevron {
      @include chevron($chevron);

      margin-bottom: -0.4em;
      margin-left: -0.2em;
      margin-right: 0.2em;
      height: 17px;
      width: 29px;
      background-size: 22px 20px;
    }
  }

  &__link {
    position: relative;
    transition: all 0.1s ease-out;

    &--arrow {
      margin-right: 1.5em;

      &::after {
        @include pseudo-arrow($arrow-right);

        position: absolute;
        display: block;
        right: -1.5em;
        bottom: 0.15em;
      }
    }

    &--arrow-left {
      margin-left: 1.75em;

      &::before {
        @include pseudo-arrow($arrow-left);

        position: absolute;
        display: block;
        left: -1.75em;
        bottom: 0.15em;
      }
    }

    &--secondary {
      @include link-init($grey, true, $grey);
    }

    &--button {
      box-sizing: border-box;
      display: inline-block;
      color: white !important;
      background-color: $link-color;
      padding: $space-sm $space-lg;
      border: 2px solid transparent;
      border-radius: 2em;
      margin: 0;
      font-size: $base-font-size;
      cursor: pointer;

      @include underline(transparent);

      &:hover {
        background-color: transparent;
        border: 2px solid $link-color;
        color: $link-color !important;
      }
    }

    &--button-secondary {
      @extend .b-link__link--button;

      border: 2px solid $link-color;
      color: $link-color !important;
      background: transparent;

      &:hover {
        background: $link-color;
        color: $white !important;
      }
    }

    &--button-inherit {
      @extend .b-link__link--button;

      border: 2px solid currentcolor;
      color: inherit !important;
      background: transparent;
    }

    &--heading {
      @include link-init($primary-color, true, $primary-color);

      &:hover {
        text-decoration: underline;
      }
    }

    &--button-wide {
      @extend .b-link__link--button;

      width: 100%;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      border-radius: 4px;

      &::after {
        position: absolute;
        right: 1em;
        top: 1.5em;
      }

      &:hover {
        text-decoration: underline;
        border-width: 1px;
      }
    }

    &--small {
      font-size: $font-xs;
    }

    &--purple {
      background-color: white;
      color: $purple-dark !important;
      border: 1px solid $purple-dark;
    }

    &--white {
      background-color: white;
      color: $link-color !important;
      border: 1px solid $link-color;

      &:hover {
        border-width: 0px;
      }
    }

    &--shadow {
      background-color: white;
      color: $link-color !important;
      box-shadow: 0 1px 5px $grey-light;
      border-width: 0px;

      &:hover {
        box-shadow: 0 1px 15px $grey-light;
        border-width: 0px;
      }
    }

    &--no-underline {
      @include underline(transparent);
    }

    @include focus-visible;
  }
}
