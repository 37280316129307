// This file contains styles for the dynamically generated forms built using
// 'xp-formbuilder', a form plugin for 'Enonic'

@mixin input-formatting-basic() {
  font-size: $meta-text;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
}

@mixin input-formatting() {
  @include input-formatting-basic;

  border: 1px solid $secondary-color;
  padding: 1em 2.5em 1em 1em;
}

// 'div' element, lives outside 'form'
#xp-formbuilder-introtext {}

// 'div' element, lives outside 'form'
#xp-formbuilder-successmessage {
  p {}
}

// 'form' element, wraps the whole form
.xp-formbuilder-form {
  line-height: 2;

  @extend .l-mb-2;

  // 'fieldset' wraps all the interactive elements
  fieldset {
    display: block;
    position: relative;
    border: 0;
    padding: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-before: 0;
    -webkit-padding-end: 0;
    -webkit-padding-start: 0;
    -webkit-padding-after: 0;

    @extend .l-mb-2;
  }

  .btn {
    text-decoration: none;

    @extend .b-button;

    &-primary {
      @extend .b-button--plain;
    }

    &-secondary {
      @extend .b-button--secondary;
    }
  }
}

// 'div' element, wraps each interactive element, except the submit button
.xp-form-input {
  position: relative;

  @extend .l-mb-1;

  label {
    display: block;
    margin-right: 1em;
    color: $black;

    span {
      &.required-star {
        margin: 0 10px 0 2px;
        color: $red-warning;
      }
    }

    span+input[type='file'] {
      display: block;
    }
  }

  textarea,
  select {
    @include input-formatting;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: relative;
    margin-right: 10px;

    @include mq.mq($from: $tablet) {
      top: -2px;
    }
  }

  input[type='email'],
  input[type='phone'],
  input[type='text'] {
    @include input-formatting;
  }

  // We would be able to do more if we had control of the markup, but we don't
  // (See: https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/)
  // The only solution here is to make it look less bad...
  input[type='file'] {
    @include input-formatting-basic;
  }
}
