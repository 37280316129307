.b-skip-to-next {
  white-space: nowrap;
  left: $space-sm;
  position: absolute;
  background-color: $white;
  z-index: 100;
  box-shadow: none;
  opacity: 0;
  max-height: 0px;
  max-width: 0px;
  padding: 0;
  
  &:focus {
    opacity: 1;
    padding: $space-xs;
    max-height: 100%;
    max-width: 100%;
  }
}
