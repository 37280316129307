// These classes are only meant for HTML-elements being affected by javascript.
// It should be used sparingly.

.js-expand-list {
  &__extra-item {
    animation-name: fadeInUp;
    animation-duration: 0.3s;
  }
}

// The wrapper needs 100% to make position sticky work
#sectionSidebar {
  height: 100%;
}

// The copy icon for headers generated with createUniqueHeadersUtil
.js-copy-icon {
  display: none;
  width: 20px;

  &:hover {
    text-decoration: underline;
  }

  @include mq.mq($until: desktop) {
    display: inline-block;
  }
}

// This class is given to a h2 when it has an 'id', so we can check that instead of
//  checking for h2[id]:hover, which is not enough... When we do the later we get
// undesirable negative margin on hover on some h2s [HDF-262]
.js-show-copy-icon:hover {
  position: relative;
  display: flex;
  cursor: pointer;

  .js-copy-icon {
    display: inline-block;
  }

  @include mq.mq($from: desktop) {
    left: -20px;
  }
}

// The 'h2' headings shown as actual 'chapter' on chapter page
a {
  h2[id] {
    display: flex;
    flex-direction: row;
    margin-left: 10px;

    // the hover on default 'h2[id]:hover' covers this case as well!
    .js-copy-icon {
      position: relative;
      top: 39px;
    }
  }
}

// The 'h2' headings shown as top recommendations on chapter page
.b-collapsible {
  h2[id] {
    display: flex;
    flex-direction: row;
  }
}
