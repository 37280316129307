.pt-article__lastUpdated {
  display: block;
  margin-top: 100px;
  margin-bottom: 20px;
}
.pt-article__component {
  margin-right: auto;
  margin-left: auto;
}

.col-10 {
  width: 1024px;
  max-width: 100%;
}
.cp-text--11 {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
