.b-statistics {
  display: flex;
  padding: 2rem;
  background-color: $blue-dark;

  @include mq.mq($until: $tablet) {
    padding: 1rem;
  }

  &__icon {
    height: auto;
    max-width: 25%;
    width: 4rem;
    margin: 0 2rem 0 0;
    flex-shrink: 0;

    @include mq.mq($until: $tablet) {
      margin: 0.5rem 1.5rem 0.5rem 0;
    }
  }

  &__text-wrapper {
    &--flex {
      display: flex;

      > :nth-child(2) {
        margin-left: 2rem;
        height: 100%;
      }
    }
  }

  &__big-text {
    font-size: $extra-large-text;
    color: $purple !important;
  }

  &__small-text {
    display: flex;
    align-items: center;
    font-size: $meta-text;
    color: $purple !important;

    &:only-child {
      height: 100%;
    }
  }
}
