.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

[href='#main'] {
  position: absolute;
  top: 0;
  right: 100%; /* moves off screen */
}

[href='#main']:focus-visible {
  right: auto;
}

a,
button,
input {
  @include focus-visible;
}
