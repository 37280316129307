.b-page-meta {
  color: $grey;
  font-size: $meta-text;

  &__text {
    display: inline-block;
    border-right: 1px solid $grey;
    padding-right: 1em;
    margin-right: 1em;
  }

  &__link {
    display: inline-block;

    @include link-init($black, true, $link-color);

    &::after {
      @include pseudo-arrow($arrow-right);
    }
  }

  > :last-child {
    border-right: 0;
  }

  // Adds some vertical spacing when the line starts breaking under tablet width
  @include mq.mq($until: $tablet) {
    > * {
      margin-top: 0.5em;
    }
  }
}
