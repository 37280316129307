.b-checkbox-group {
  display: inline-block;
  position: relative;
  border: 0;
  padding: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-before: 0;
  -webkit-padding-end: 0;
  -webkit-padding-start: 0;
  -webkit-padding-after: 0;

  &__legend {
    font-weight: bold;
    padding: 0;
    margin: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;

    &--border {
      width: 100%;
      padding-bottom: calc(#{map-get($space-map, xs)} - 7px);
      border-bottom: 1px solid $grey-light;
      margin-bottom: calc(#{map-get($space-map, xs)} - 7px);
    }
  }

  &__input {
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    vertical-align: middle;

    &:disabled ~ .b-checkbox-group__indicator {
      background: $grey-light;
      border-color: $grey !important;
      cursor: not-allowed;
    }

    &:hover ~ .b-checkbox-group__indicator {
      border-color: $link-color;
    }

    &:checked ~ .b-checkbox-group__indicator {
      border-color: $link-color;
      background-color: $link-color;
      transition: background 0.2s ease;

      &::after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        background-image: url('../static/icons/check.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 1px;
      }
    }
  }

  &__label {
    display: flex;
    align-content: flex-start;
    margin-top: 0.5rem;
    cursor: pointer;
    font-size: $meta-text;

    &:focus-within {
      outline: 3px solid $link-color;
      outline-offset: 2px;
    }
  }

  &__indicator {
    flex-shrink: 0;
    position: relative;
    margin-top: 0;
    margin-right: 0.5rem;
    border-radius: 4px;
    border: 2px solid $grey;
    background: white;
    width: 22px;
    height: 22px;
    transition: border-color 0.2s ease, max-height 0.2s ease,
      transform 0.2s ease;

    &::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      background: transparent;
    }
  }
}
