.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // IE 11
		max-width: 88vw;
	}
}

.t-body-text .videoWrapper iframe {
	height: auto;
}

.t-body-text .videoWrapper.videoWrapper--full-height iframe {
	height: 100%;
}

.videoWrapper__close {
	background: $primary-color-lightest;
	border-bottom: 1px solid $primary-color;
	width: 100%;
	margin: auto;
	padding: $space-xs;
	top: 0;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 999;
	display: none;
	position: absolute;
}

.videoWrapper__fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 998;

	.videoWrapper__close {
		display: inline-block;
	}

	iframe {
		height: 93vh !important;
		height: calc(100vh - 60px) !important;
		margin-top: 60px;
	}
}
