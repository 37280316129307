
// Styling copied from e-helse
// TODO: Investigate if this can be changed to hdir design tokens
:root {
  /* Colors token */
  --color-white: #fdfdfd;
  --color-blue: #0069e8;
  --color-blueC: #035196;
  --color-blueD: #80a7d6;
  --color-blue-light: #F0F5FF;
  --color-gray-lightest: #efefef;
  --color-gray-lighter: #e5e5e5;
  --color-gray-light: #727278;
  --color-gray: #53535c;
  --color-gray-50: #53535c80;
  --color-grayC: #767676;
  --color-gray-dark: #32323b;
  --color-pink: #f783fe;
  --color-purple: #9d1dec;
  --color-aubergine: #281c2c;
  --color-red: #ff6b6b;
  --color-green: #7bf5bc;
  --color-turquoise: #00bcd5;
  --color-ocean: #037194;
  --color-oceanC: #035169;
  --color-sky: #80A6D6;
  --color-bg-menu: #ffe9e9;
  --color-bg-blue: #e4f0f4;
  --color-bg-green: #defcee;
  --color-bg-purple: #f7eaff;
  --color-bg-ruby: #fcf3f9;
  --color-rubyC: #7c145d;
  --color-border-purple: #ae2cfe;

  /* Semantic color tokens */
  --color-primary: var(--color-blue);
  --color-text: var(--color-gray-dark);
  --color-border: var(--color-gray-lighter);

  /* Spacing mobile */
  --spacing-05: 5px;
  --spacing-10: 5px;
  --spacing-20: 10px;
  --spacing-40: 20px;
  --spacing-60: 30px;
  --spacing-80: 40px;
  --spacing-100: 50px;
  --spacing-120: 60px;
  --spacing-200: 100px;

  /* Font Weight */
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Spacing desktop */
  @media (width > 576px) {
    --spacing-05: 5px;
    --spacing-10: 10px;
    --spacing-20: 20px;
    --spacing-40: 40px;
    --spacing-60: 60px;
    --spacing-80: 80px;
    --spacing-100: 100px;
    --spacing-120: 120px;
    --spacing-200: 200px;

    /* Font size desktop */
    --font-size-xxxl: 80px;
    --font-size-xxl: 36px;
    --font-size-xl: 28px;
    --font-size-l: 22px;
    --font-size-m: 17px;
    --font-size-s: 15px;
  }

  --border-radius: 15px;
}
