figure, .editor-align-justify, .justify {
  margin: 2em 0;

  img {
    max-width: 100%;
    height: auto;
  }
}

.editor-align-left, .left {
  margin: 2em 0;

  @include mq.mq($from: $tablet) {
    max-width: 50%;
    float: left;
    margin: 2em 2em 2em 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.editor-align-right, .right {
  margin: 2em 0;

  @include mq.mq($from: $tablet) {
    max-width: 50%;
    float: right;
    margin: 2em 0 2em 2em;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.editor-align-center, .center {
  margin: 2em 0;

  @include mq.mq($from: $tablet) {
    max-width: 70%;
    margin: 2em auto;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

figcaption {
  font-size: $font-xs;
}
