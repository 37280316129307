.cookie-panel-banner,
.cookie-panel-settings {
  z-index: 1000;
  position: fixed;
  bottom: 4%;
  right: 2%;
  max-width: 31rem;
  background-color: var(--design-color-surface-neutral-primary);
  border-radius: var(--design-border-radius-sm);
  border: var(--design-border-sm) solid var(--design-color-border-neutral-inverted);

  @include mq.mq($until: $desktop) {
    right: 0;
  }

  @include mq.mq($until: $tablet) {
    bottom: 0;
  }
}

.cookie-panel-banner__inner,
.cookie-panel-settings__inner {
  padding: var(--design-spacing-xl);
  color: var(--design-color-text-neutral-inverted);

  @include mq.mq($until: $tablet) {
    padding: var(--design-spacing-lg);
  }
}

#cookie-panel-banner-accept-button,
#cookie-panel-banner-settings-button,
#cookie-panel-settings-save-button,
#cookie-panel-banner-reject-button {
  border: var(--design-border-md) solid transparent;
  border-radius: var(--design-border-radius-lg);
  font-size: var(--design-font-md);
  padding: var(--design-spacing-xs) var(--design-spacing-md);
  margin-right: var(--design-spacing-md);
  margin-bottom: var(--design-spacing-md);
  font-weight: var(--design-font-weight-semi-bold);
  transition: all 0.2s ease;
  cursor: pointer;
}

#cookie-panel-banner-accept-button,
#cookie-panel-settings-save-button {
  background-color: var(--design-color-surface-action-secondary-default);
  color: var(--design-color-text-action-default);

  &:hover,
  &:focus {
    background-color: var(--design-color-surface-action-secondary-hover);
  }

  &:active {
    background-color: var(--design-color-surface-action-secondary-active);
  }
}

#cookie-panel-banner-settings-button,
#cookie-panel-banner-reject-button {
  background-color: transparent;
  border-color: var(--design-color-border-neutral-inverted);
  color: var(--design-color-text-neutral-inverted);

  &:hover,
  &:focus {
    background-color: var(--design-color-surface-action-primary-hover);
  }

  &:active {
    background-color: var(--design-color-surface-action-primary-active);
  }
}

.cookie-panel-banner {
  &__title {
    font-weight: var(--design-font-weight-semi-bold);
    margin-bottom: var(--design-spacing-md);
  }

  &__description {
    margin-bottom: var(--design-spacing-lg);
  }

  &__buttons {
    display: flex;
    flex-flow: row wrap;
  }
}

.cookie-panel-settings {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &__inner {
    h2,
    h3 {
      font-weight: var(--design-font-weight-semi-bold);
      line-height: var(--design-font-line-height);
    }
  }

  &__categories {
    &__category {
      margin-top: var(--design-spacing-xl);
    }

    &__category-header {
      display: flex;
      flex-direction: row;
      gap: var(--design-spacing-md);
      margin-bottom: var(--design-spacing-xs);

      .cookie-panel-switch {
        [type="checkbox"]:checked:disabled + .cookie-panel-switch__toggle {
          opacity: 1;
        }

        [type="checkbox"]:checked:disabled + .cookie-panel-switch__toggle::before {
          background: var(--design-color-text-neutral-subtle);
        }

        [type="checkbox"]:checked:disabled + .cookie-panel-switch__toggle::after {
          background: var(--design-color-text-neutral-disabled);
        }

        [type="checkbox"] + .cookie-panel-switch__toggle::before {
          opacity: 1;
          background: var(--design-color-text-neutral-disabled);
        }

        [type="checkbox"] + .cookie-panel-switch__toggle::after,
        [type="checkbox"]:checked + .cookie-panel-switch__toggle::after {
          background: var(--design-color-text-action-primary-default);
        }

        [type="checkbox"]:checked + .cookie-panel-switch__toggle::before {
          background: var(--design-color-text-action-secondary-disabled);
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: var(--design-spacing-xl);

    a {
      color: var(--design-color-text-neutral-inverted);
      box-shadow: none;
      text-decoration: underline;

      &:hover {
          font-weight: var(--design-font-weight-semi-bold);
      }
    }
  }
}